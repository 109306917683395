import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { Rate, Tooltip, Skeleton } from "antd";
import MediaQuery from "react-responsive";
import { IoIosCash } from "react-icons/io";
import { HiReceiptRefund } from "react-icons/hi";
import { MdFreeBreakfast } from "react-icons/md";
import "./RoomUI.css";
import { HOTEL_DETAIL } from "../RouteNames";
import {getCurrency} from "../AxiosHelper";

const RoomComponent = (props) => {
  const handleClick = (event) => {
    if (!!event) event.preventDefault();

    const state = {
      correlationId: props.history.correlationId,
      place: props.place,
      startDate: props.startDate,
      endDate: props.endDate,
      noofAdults: props.noofAdults,
      noofChild: props.noofChild,
      noofroom: props.noofroom,
      lattitude: props.geoCode.lat,
      longittude: props.geoCode.long,
      panes: props.location.panes,
    };


    const timeStamp  = new Date().getTime();
    const key = `VDHOL-${props.id}-${timeStamp}`;
    localStorage.setItem(key, JSON.stringify(state));

    const win = window.open(HOTEL_DETAIL.replace(":slug", props.id)+`?d=${timeStamp}`, "_blank");
    win.focus();

    // if (
    //   event.ctrlKey ||
    //   event.shiftKey ||
    //   event.metaKey || // apple
    //   (event.button && event.button == 1) // middle click, >IE9 + everyone else
    // ) {
    //
    //   alert("Open in new tab disabled temporarily");
    //   return;
    // }
    //
    // props.history.push({
    //   pathname: HOTEL_DETAIL.replace(":slug", props.id),
    //   state: {
    //     correlationId: props.history.correlationId,
    //     place: props.place,
    //     startDate: props.startDate,
    //     endDate: props.endDate,
    //     noofAdults: props.noofAdults,
    //     noofChild: props.noofChild,
    //     noofroom: props.noofroom,
    //     lattitude: props.geoCode.lat,
    //     longittude: props.geoCode.long,
    //     panes: props.location.panes,
    //   },
    // });
  };
  let currency = getCurrency();

  const commas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const skele = (
    <>
      <MediaQuery maxWidth={775}>
        <Skeleton
          title={false}
          active
          paragraph={{ rows: 1, width: ["40vw"] }}
        />
      </MediaQuery>
      <MediaQuery minWidth={776}>
        <Skeleton
          title={false}
          active
          paragraph={{ rows: 1, width: ["10vw"] }}
        />
      </MediaQuery>
    </>
  );

  return (
    <div key={props.id} className="room">
      <Link to={HOTEL_DETAIL.replace(":slug", props.id)} onClick={handleClick}>
        <div
          className="roomimage"
          style={{ backgroundImage: `url(${props.heroImage})` }}
        ></div>
      </Link>

      <div className="provider-name" style={{
        backgroundColor:"red", color:'white', padding:2,
        margin:"5px 0px", borderRadius:3, display:'none'}}>
        {props.rate.publishedRateProviderName}
      </div>

      <div className="roomInfo">
        {props.type  && (<div className="SearchResultsRoomType">{props.type}</div>)}
        {props?.options?.refundable && <div className="SearchResultsRoomType">Refundable</div>}
        <Rate
          disabled={true}
          value={Number(props.starRating)}
          className="Searchstars"
        />
      </div>
      <div
        className="searchHeading"
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      >
        {props.name}
      </div>
      <div className="subHeading">
        {props.contact.address.city.name && (
          <span>{props.contact.address.city.name}</span>
        )}
        {props.contact.address.country.name && (
          <span>, {props.contact.address.country.name}</span>
        )}
        {props.distance && props.distance > 0 && (
          <span> ({parseFloat(props.distance).toFixed(1)}km)</span>
        )}

      </div>

      <div className="HotelIcons">
        {props?.options?.payAtHotel && (
          <Tooltip title="Pay at Hotel">
            <IoIosCash />
          </Tooltip>
        )}
        {/*{props?.options?.refundable && (*/}
        {/*  <Tooltip title="Refundable">*/}
        {/*    <HiReceiptRefund />*/}
        {/*  </Tooltip>*/}
        {/*)}*/}
        {props?.options?.freeBreakfast && (
          <Tooltip title="Free Breakfast">
            <MdFreeBreakfast />
          </Tooltip>
        )}
      </div>

      <div
        className="SearchRates"
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      >
        {props.rate ? currency + " " + commas(props.rate.totalRate) : skele}
      </div>
    </div>
  );
};

export default RoomComponent;
