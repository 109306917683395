import React from "react";
import { Button, Icon, Select } from "antd";
import "./SearchBar.css";
import { RoomTab } from "./RoomTab";

const { Option } = Select;
const RoomInfoMobile = (props) => {
  const options = [];
  const { panes } = props;

  let currentPane;
  panes.forEach((pane, index) => {
    if (pane.key === props.activeKey) {
      currentPane = pane;
    }
    options.push(
      <Option key={pane.key} value={pane.key}>{`Room ${index + 1}`}</Option>
    );
  });

  return (
    <div className="RoomInfoMobile">
      {props.closable && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Icon
            type="close-circle"
            style={{ fontSize: "3rem", marginBottom: "1rem" }}
            onClick={() => props.setmodal(false)}
          />
        </div>
      )}

      <div className="inputContainer">
        <div className="inputDescription">Room</div>
        <Select
          value={props.activeKey}
          style={{ width: "100%" }}
          onChange={(value, option) => props.onChange(value)}
        >
          {options}
        </Select>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          type="primary"
          style={{ width: "calc(50% - .5rem)" }}
          onClick={() => props.onEdit(props.activeKey, "add")}
        >
          Add Room
        </Button>
        <div style={{ width: "1rem" }} />
        <Button
          type="primary"
          ghost
          style={{ width: "calc(50% - .5rem)" }}
          disabled={currentPane.closable === false}
          onClick={() => props.onEdit(props.activeKey, "remove")}
        >
          Delete Room
        </Button>
      </div>

      <div className="RoomInfo">
        <RoomTab
          pane={currentPane}
          changeAdultCount={props.changeAdultCount}
          changeChildCount={props.changeChildCount}
          changeChildAge={props.changeChildAge}
        />
      </div>

      {props.showSaveButton && (
        <Button
          type="primary"
          style={{ width: "100%", marginTop: "auto" }}
          onClick={() => {
            props.setmodal(false)
            props.handleSearch();
          }}
        >
          Search
        </Button>
      )}
    </div>
  );
};

export default RoomInfoMobile;
