import SearchBar from "../../../components/SearchBar/SearchBar";
import React from "react";

const HomeSearchBar = ({hasProvider, setOpenLogin, history}) => {
  return (
      <div className="HeroCenter">
        <div className="HeroText"  style={{
          color : 'white',
          height:'auto'
        }}>
          Travel and enjoy FREE roaming passes in 72 countries with Yoodo!
          <div style={{marginTop:20, fontSize:'2rem'}}>
            If you are not a Yoodo customer, just switch to Yoodo,book your rooms with us and enjoy FREE roaming
          </div>
        </div>
        <SearchBar hasProvider={hasProvider} showLogin={() => setOpenLogin(new Date())} history={history} />
        <div className="HeroTextBottom">
          We have over 900,000 properties worldwide
        </div>
      </div>
  )
}

export default HomeSearchBar;
