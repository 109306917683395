import React, { useState } from "react";
import background from "../images/newsletter.jpg";
import { Button, Input } from "antd";
import "./NewsLetter.css"

// TODO: Newsletter
export const NewsLetter = () => {
  const [email, setemail] = useState("");

  return (
    <div
      className="NewsLetter"
      style={{ backgroundImage: `url( ${background} )` }}
    >
      <div className="NewsletterContainer">
        <div className="NewsLettersubHeading">Subscribe to our Newsletter</div>
        <div className="NewsLetterHeading">Ready to get deals? Let’s get started!</div>
      </div>
      <div className="spacing" />
      <div className="rowflex">
        <Input type="text" placeholder="Enter your Email"></Input>
        <Button style={{color:"#d93b4f", border: "none"}} type="ghost">Subscribe</Button>
      </div>
    </div>
  );
};
