import React from "react";
import RoomRow from "./RoomRow";

export const RoomTab = (props) => {
  let { pane } = props;
  return (
    <div>
      <RoomRow
        key="adults"
        info="Adults"
        subinfo="Age above 12 years"
        defaultValue={1}
        min={1}
        changeInputValue={(val) => props.changeAdultCount(val, pane.key)}
        value={props.pane.adults}
      />
      <RoomRow
        key="children"
        info="Children"
        subinfo="12 years and below"
        min={0}
        defaultValue={0}
        changeInputValue={(val) => props.changeChildCount(val, pane.key)}
        value={props.pane.children}
      />
      {pane.childrenInfo.map((value, index) => (
        <RoomRow
          key={index}
          max={12}
          min={1}
          defaultValue={6}
          info={`Child ${index + 1}'s age`}
          changeInputValue={(val) => props.changeChildAge(val, index, pane.key)}
          value={value}
        />
      ))}
    </div>
  );
};
