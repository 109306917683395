import React, { useState } from "react";
import { Rate, Icon, Tooltip } from "antd";
import { HotelDescription } from "../../components/HotelDescription";
import { Link } from "react-router-dom";
import Landmarks from "../../components/MobileRoom/Landmarks";
import HotelLocation from "../../components/MobileRoom/HotelLocation";
import RoomsAndRates from "../../components/HotelDesktop/RoomsAndRates";
import FooterPage from "../../components/Footer";
import SearchBar from "../../components/SearchBar/SearchBar";
import { IoIosArrowUp } from "react-icons/io";
import ScrollTop from "react-scrolltop-button";
import ImageGallery from "react-image-gallery";
import Modal from "react-modal";
import moment from "moment";

const ShowMore = ({ state, setState }) => (
  <Link
    to="#"
    onClick={(e) => {
      e.preventDefault();
      setState(!state);
    }}
    style={{ color: "#F48247" }}
  >
    {state ? "View less" : "Show more"}
  </Link>
);

export const AccommodationDetailsDesktop = (props) => {
  const [about, setabout] = useState(false);
  const [modal, setmodal] = useState(false);
  const [galleryStart, setgalleryStart] = useState(0);
  const { data } = props;
  const { images, name, contact, starRating } = data;

  const days = Math.round(
    (Date.parse(props.location.state.endDate) -
      Date.parse(props.location.state.startDate)) /
      (1000 * 60 * 60 * 24)
  );

  const imagesArray = images.map((image) => {
    return {
      original:
        image.links[1] && image.links[1].url
          ? image.links[1].url
          : image.links[0].url,
      thumbnail:
        image.links[1] && image.links[1].url
          ? image.links[1].url
          : image.links[0].url,
      description: image.caption,
    };
  });

  const showImages = [false, false, false, false];

  if( images.length > 0 ) {
    const len = images.length > 4 ? 4 : images.length
    for (let i = 0; i < len; i++) {
      showImages[i] =
          !!images[i].links[1] && !!images[i].links[1].url
              ? images[i].links[1].url
              : images[i].links[0].url;
    }
  }

  const openModal = (value) => {
    setgalleryStart(value);
    setmodal(true);
  };


  if( data.facilities.length % 2 !== 0 ){
    data.facilities.push(null)
  }

  return (
    <div className="AccommodationDetailsDesktop">
      <Modal isOpen={modal}>
        <div
          style={{ display: "flex", justifyContent: "flex-end", zIndex: 1000 }}
        >
          <Icon
            type="close-circle"
            style={{ fontSize: "3rem", marginBottom: "1rem" }}
            onClick={() => setmodal(false)}
          />
        </div>
        <ImageGallery
          items={imagesArray}
          showFullscreenButton={false}
          showPlayButton={false}
          startIndex={galleryStart}
        />
      </Modal>

      {props.bookingModal}
      {props.policyModal()}

      {props.header}
      <ScrollTop
          style={{right:'7%'}}
        icon={<IoIosArrowUp />}
        className="scrollToTopClass"
        text={<IoIosArrowUp />}
      />
      <div className="acccontainer">
        <div
          style={{
            display: "flex",
            height: "60vh",
            justifyContent: "space-between",
            margin: "3rem 0",
          }}
        >
          <div
            style={{ backgroundImage: `url( ${showImages[0]} )` }}
            className="mainImage"
            onClick={() => openModal(0)}
            value={0}
          />
          <div
            style={{
              display: "flex",
              height: "60vh",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div
              style={{ backgroundImage: `url( ${showImages[1]} )` }}
              className="sideImage"
              onClick={() => openModal(1)}
              value={1}
            />
            <div
              style={{ backgroundImage: `url( ${showImages[2]} )` }}
              className="sideImage"
              onClick={() => openModal(2)}
              value={2}
            />
            <div
              style={{ position: "relative", cursor: "pointer" }}
              onClick={() => openModal(3)}
            >
              <div
                style={{ backgroundImage: `url( ${showImages[3]} )` }}
                className="sideImage"
                value={3}
              />

              <div className="imageOverlay">
                <div
                  style={{
                    margin: "auto",
                    fontWeight: "bold",
                    fontSize: "3rem",
                    color: "white",
                  }}
                >
                  +{data.imageCount - 4}
                </div>
              </div>
            </div>
          </div>
        </div>

        <SearchBar
          history={props.history}
          place={props.location.state.place}
          startDate={props.location.state.startDate}
          endDate={props.location.state.endDate}
          panes={props.location.state.panes[0]}
          totalAdults={props.location.state.noofAdults}
          totalChildren={props.location.state.noofChild}
          totalRooms={props.location.state.noofroom}
        />

        <div className="information">
          <div className="mainHeading">{name}</div>
          <div className="grayInfo">
            {contact.address.city.name}, {contact.address.country.name}
          </div>
          <div>
            <div className="roomInfo">
              <div className="SearchResultsRoomType">{data.type}</div>
              <Rate
                disabled={true}
                value={Number(starRating)}
                className="Searchstars"
              />
            </div>

            <div style={{ marginTop: "1rem" }}>
              <div className="subHeading">About the Hotel</div>
              <div
                className="grayInfo"
                style={
                  about
                    ? { height: "auto" }
                    : { height: "15rem", overflow: "hidden" }
                }
              >
                <HotelDescription descriptions={data.descriptions} />
                <div className="subHeading" style={{color:'black'}}>Facilities :</div>
                {data.facilities.length > 0 &&
                  <div style={{display: "flex", flexWrap: 'wrap', marginLeft:'-10px'}}>
                    {data.facilities.map((faci, index) => <div style={{flex:'1 0 0%'}}>
                      <div className="Facilities" style={{height:75, width:'18vw'}} key={index}>
                        {" "}
                        {faci &&
                            <>
                              <span>{faci.name}</span>
                              <Icon style={{color: "#F48247"}} type="check" />
                            </>
                        }
                      </div>
                    </div>)}
                  </div>
                }
              </div>
              {<ShowMore state={about} setState={setabout} />}
            </div>

            <div style={{ marginTop: "1rem" }}>
              <div className="subHeading">Location and Landmarks</div>

              <Landmarks
                nearByAttractions={data.nearByAttractions}
                number={8}
              />
            </div>

            <div style={{ marginTop: "1rem" }}>
              <HotelLocation
                name={name}
                geoCode={data.geoCode}
                address={data.contact.address}
              />
            </div>

            <div style={{ marginTop: "1rem" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="subHeading">Booking details</div>
              </div>
              {/*
              <div style={{ marginBottom: "1rem" }}>
                Details for{" "}
                <b>
                  {props.location.state.noofroom}{" "}
                  {props.location.state.noofroom === 1 ? " room" : " rooms"},{" "}
                  {days} nights
                </b>{" "}
                and{" "}
                <b>
                  <Tooltip title={props.childrenInfoString}>
                    {props.location.state.noofAdults} Adult
                    {props.location.state.noofAdults > 1 && "s"}{" "}
                    {props.location.state.noofChild > 0 &&
                      (props.location.state.noofChild === 1
                        ? "and 1 child"
                        : `and ${props.location.state.noofChild} children`)}
                  </Tooltip>
                </b>
              </div> */}

              <div
                style={{
                  padding: "1rem",
                  border: "1px solid #E9EDEF",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <div style={{ color: "#858586" }}>Check in</div>
                  <div>
                    {moment(props.location.state.startDate).format("dddd")},{" "}
                    {props.location.state.startDate} at{" "}
                    {data.checkinInfo.beginTime}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderLeft: "1px solid #E9EDEF",
                    paddingLeft: "1rem",
                    width: "50%",
                  }}
                >
                  <div style={{ color: "#858586" }}>Check out</div>
                  <div>
                    {moment(props.location.state.endDate).format("dddd")},{" "}
                    {props.location.state.endDate} at {data.checkoutInfo.time}
                  </div>
                </div>
              </div>

              <div
                style={{
                  padding: "1rem",
                  border: "1px solid #E9EDEF",
                  marginBottom: "2rem",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    marginRight: "auto",
                  }}
                >
                  <div style={{ color: "#858586" }}>Rooms</div>
                  <div>{props.location.state.noofroom}</div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "auto",
                    borderLeft: "1px solid #E9EDEF",
                    paddingLeft: "1rem",
                  }}
                >
                  <div style={{ color: "#858586" }}>Nights</div>
                  <div>{days}</div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderLeft: "1px solid #E9EDEF",
                    paddingLeft: "1rem",
                    marginRight: "auto",
                  }}
                >
                  <div style={{ color: "#858586" }}>Adults</div>
                  <div>{props.location.state.noofAdults}</div>
                </div>

                {props.location.state.noofChild > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderLeft: "1px solid #E9EDEF",
                      paddingLeft: "1rem",
                      marginRight: "auto",
                    }}
                  >
                    <div style={{ color: "#858586" }}>Children</div>
                    <div>{props.location.state.noofChild}</div>
                  </div>
                )}

                {props.location.state.noofChild > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderLeft: "1px solid #E9EDEF",
                      paddingLeft: "1rem",
                      marginRight: "auto",
                    }}
                  >
                    <div style={{ color: "#858586" }}>Age</div>
                    <div>{props.childrenInfoString(true)}</div>
                  </div>
                )}
              </div>
            </div>

            <RoomsAndRates
              hotelId={data.id}
              location={props.location}
              handleSelection={props.handleSelection}
              // selected={props.selectedToken}
              // setPaymentData={props.setPaymentData}
            />
          </div>
        </div>
      </div>
      <FooterPage />
    </div>
  );
};
