import React from "react";
import "./LegalDocuments.css";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";

const TOS = () => {
  return (
    <div className="docsPage" style={{ height: "auto" }}>
      <Header />
      <div className="docsContainer">
        <div className="docsHeading">Terms & Conditions</div>
        <p className="docsPara">
          <div className="docsSubHeading">1. User agreement</div>
          a. This agreement is between you and Visitors Deals (brand owned by
          Hotstores Solutions (M) Sdn Bhd 784355-P) (referred to in this
          document as "us" or "we").
          <br />
          b. Service Providers ( Hotels )
          <br />
          c. By visiting and/or using the Visitors Deals website (the "Website")
          you agree to be bound by the terms of this agreement ("agreement").
          <br />
          d. We may modify this agreement at any time and such modifications
          shall be effective immediately upon posting.
          <br />
          <div className="docsSubHeading">2. Registration</div>
          a. In order to access any other features of the Website you might need
          to be a registered member.
          <br />
          b. When registering to become a member, you must provide Personal
          Information including your first and last name, and a valid email
          address. You agree to provide accurate and complete information and to
          keep this information current. You information is stored securely, and
          will only be used and disclosed in accordance with our Privacy Policy
          (which can be accessed here: http:// visitorsdeals.com/about/privacy).
          <br />
          c. You are solely responsible for the activity that occurs on your
          account, and you must keep your account password secure.
          <br />
          d. If you suspect or become aware of any unauthorized use of your
          account or that your password is no longer secure, you should notify
          us immediately contactus@visitorsdeals.com we are not liable for any
          unauthorized use of your account.
          <br />
          <div className="docsSubHeading">3.Our Service</div>
          VisitorsDeals is an intermediary which brings you the best rooms at
          the best price from global service providers, as an intermediary we
          facilitate the discovery and booking process & send you all the
          confirmation emails for the transaction. As an intermediary we are
          not imposing any terms and condition on the purchase of the product or
          service which you purchase through us, hereby we would like to state
          that all the terms & conditions are imposed by the Service Providers (
          hotels ). We hope that prior to booking you have read all the terms &
          conditions imposed by the Service Providers ( hotels ).
          <br />
          <div className="docsSubHeading">4. Payment Process</div>
          For the payment process we have engaged 3rd party payment gateway
          providers as a payment processor, payments will be processed safely
          from your credit/debit card or bank accounts to the Service
          Providers ( hotel ) directly or the appointed partner. VisitorsDeals
          will not be liable for any unauthorized or wrong payments charged by
          the Service Providers ( Hotel ). In the event of fraud or any
          unauthorized use of your credit card, your bank or credit – card
          company must bear the risk and clear the charges raised from such
          misuse.
          <br />
          <div className="docsSubHeading">5. Cancelation & Refund</div>
          By making a booking though VisitorsDeals , you accept & agree to all
          the cancelation and no - show policies imposed by the Service
          Providers ( Hotel ). The general cancelation , refund and other terms
          & conditions are different from provider to provider , or in some
          instances product to product or promotion to promotion , by confirming
          the booking with us we assume that you have read all the terms &
          conditions and you are agreeable to all the conditions set by the
          Service Providers ( Hotel ) . You can easily cancel or ask for a
          refund ( if allowed ) through VisitorsDeals by login into your account
          and following the cancellation or refund process .
          <br />
          <div className="docsSubHeading">6. Communication</div>
          By completing the booking with us, we assume that you have agreed for
          us to use your contact details & emails to notify you on the booking
          status and any other pertinent notification in regards to the booking
          .
          <br />
          <div className="docsSubHeading">7. Disclaimer</div>
          a. To the extent allowed by law, we exclude all liability from you or
          anyone else for loss or damage of any kind (however caused or arising)
          relating in any way to the content of the Website /or any linked
          website including, but not limited to, loss or damage you might suffer
          as a result of .
          <br />
          b. Errors, mistakes or inaccuracies on the Website /or any linked
          website
          <br />
          c. You acting, or failing to act, on any information contained on or
          referred to on the Website and / or any linked website .
          <br />
          d. Any interruption or cessation of transmission to or from the
          Website /or any linked website .
          <br />
          e. Any bugs, viruses, Trojans or other malevolent code or
          communications which may be transmitted to or through our website /or
          any linked website
          <br />
          f. The merchantability or fitness for any purpose of any good or
          service of our website /or any linked website .
          <br />
          <div className="docsSubHeading">8. Use of Website by you</div>
          a. You agree not to access (or attempt to access) any part of the
          Website by any means other than through the interface provided by us.
          <br />
          b. You agree that you will not engage in any activity that interferes
          with or disrupts the website or the servers and networks that host the
          Website. You may not use data mining, robots, screen scraping or
          similar data gathering and extraction tools on the Website except with
          our prior written consent.
          <br />
          c. You agree not to, or attempt to, circumvent, disable or otherwise
          interfere with security-related features of the website or features
          that prevent or restrict use or copying of any content or enforce
          limitations on the use of the website or the content therein.
          <br />
          d. You agree not to use, copy, distribute or commercialize content
          except as permitted by this agreement, by law or with our prior
          written consent.
          <br />
          e. You understand and agree that any suspected fraudulent, abusive or
          illegal activity may be referred to appropriate law enforcement
          authorities.
          <br />
          f. We reserve the right to cancel your registration at any time, in
          the event of cancellation of registration, our decision is final and
          binding.
          <br />
          <div className="docsSubHeading">9. Information on this Website</div>
          a. Information about goods and services on the website is based on
          material provided by third party businesses or Service Providers (
          Hotel )
          <br />
          b. You understand and agree that we cannot be held responsible for
          errors or omissions caused by incorrect or inadequate information
          supplied to us  by the Service Providers ( Hotel ) .
          <br />
          c. You agree to make your own enquiries to verify information provided
          and to assess the suitability of products or services before you
          purchase.
          <br />
          <div className="docsSubHeading">10. Liability</div>
          a. All information, such as comments, messages, text, files, images,
          photos, video, sounds and other materials ("content") posted on,
          transmitted through or linked from the website are the sole
          responsibility of the Service Providers ( Hotel ) from whom such
          content originated.
          <br />
          b. You understand that we do not control and are not responsible for
          content made available through the website unless it originates from
          us.  <br />
          <div className="docsSubHeading">11. Intellectual property</div>
          a. We reserve all intellectual property rights, including but not
          limited to, copyright in material and/or services provided by us.
          Nothing in the agreement gives you a right to use any of our marketing
          material, business names, trademarks, logos, domain names or other
          distinctive brand features.
          <br />
          b. Other trademarks used on the Website that belong to third parties
          are used with their consent and remain the intellectual property of
          that party.
          <br />
          c. If you correspond or otherwise communicate with us, you
          automatically grant to us an irrevocable, perpetual, non-exclusive,
          royalty-free, worldwide license to use, copy, display and distribute
          the content of your correspondence or communication and to prepare
          derivative works of the content or incorporate the content into other
          works in order to publish and promote such content. This may include,
          but is not limited to, publishing testimonials on the Website and
          developing your ideas and suggestions for improved products or
          services we provide.
          <br />
          <div className="docsSubHeading">12. Change of Control</div>
          Subject to relevant laws, if we merge, sell or otherwise change
          control of our business or this Website, we reserve the right, without
          giving notice or seeking consent, to transfer or assign your Personal
          Information, content and rights that we have collected from you and
          any agreements we have made with you.
          <br />
          <div className="docsSubHeading">13. Indemnity</div>
          You will at all times indemnify, and keep indemnified, us and our
          directors, officers, employees and agents from and against any loss
          (including reasonable legal costs and expenses on a full indemnity
          basis) or liability incurred or suffered by you or by us arising from
          any claim, demand, suit, action or proceeding by any person against
          you or us where such loss or liability arose out of, in connection
          with or in respect of your conduct or breach of this agreement.
          <br />
           
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TOS;
