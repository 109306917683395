import React, { useState } from "react";
import "./Footer.css";
import { FooterDesktop } from "./Footer/FooterDesktop";
import { FooterMobile } from "./Footer/FooterMobile";
import MediaQuery from "react-responsive";

const FooterPage = () => {
  const [currency, setCurrency] = useState(
    localStorage.getItem("currency") || "MYR"
  );

  const {hasProvider, config : pConfig} = JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV+'_VD_PROVIDER')) ?? {hasProvider:false, config:{}}


  const changeCurrency = (val) => {
    setCurrency(val);
    localStorage.setItem("currency", val);
  };
  return (
    <>
      <MediaQuery maxWidth={775}>
        <FooterMobile showEsim={!hasProvider} currency={currency} changeCurrency={changeCurrency} />
      </MediaQuery>
      <MediaQuery minWidth={776}>
        <FooterDesktop showEsim={!hasProvider} currency={currency} changeCurrency={changeCurrency} />
      </MediaQuery>
    </>
  );
};

export default FooterPage;
