import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import SearchBar from "../../components/SearchBar/SearchBar";
import "./Home.css";

import { PopularDestinations } from "./PopularDestinations";
import { NewsLetter } from "../../components/NewsLetter";

import des1 from "../../images/Home/des1.jpg"
import des2 from "../../images/Home/des2.jpg"
import des3 from "../../images/Home/des3.jpg"
import des4 from "../../images/Home/des4.jpg"
import des5 from "../../images/Home/des5.jpg"
import des6 from "../../images/Home/des6.jpg"
import des7 from "../../images/Home/des7.jpg"
import des8 from "../../images/Home/des8.jpg"
import des9 from "../../images/Home/des9.jpg"
import des10 from "../../images/Home/des10.jpg"

import yoodoBg from "../../images/provider/home/yoodo_bg.jpg"
import HomeContent from "../../components/Provider/Yoodo/HomeContent";
import HomeSearchBar from "../../components/Provider/Yoodo/HomeSearchBar";

export const HomeDesktop = ({hasProvider, provider, ...props}) => {
  const [currency, setcurrency] = useState("MYR");
  const [openLogin, setOpenLogin] = useState(false);

  useEffect(() => {
    setcurrency(localStorage.getItem("currency"));
  }, []);

  const DefaultHomeContent = () => {
    return (
        <div>
          <div className="homeHeading">
            Book your room in these cities and get FREE 1GB of data for roaming. DAILY.
          </div>
          <div className="Row">
            <PopularDestinations
                place="Kuala Lumpur, Malaysia"
                placeId={228226}
                currency={currency}
                cost="9999"
                url={des1}
                showLogin={() => setOpenLogin(!openLogin)}
                history={props.history}
                imageClass="smallImage"
            />
            <PopularDestinations
                place="Singapore"
                placeId={246673}
                currency={currency}
                cost="9999"
                url={des2}
                showLogin={() => setOpenLogin(!openLogin)}
                history={props.history}
                imageClass="smallImage"
            />
            <PopularDestinations
                place="Sydney, Australia"
                currency={currency}
                placeId={383923}
                cost="9999"
                url={des3}
                showLogin={() => setOpenLogin(!openLogin)}
                history={props.history}
                imageClass="smallImage"
            />
            <PopularDestinations
                place="London, United Kingdom"
                placeId={435089}
                currency={currency}
                cost="9999"
                url={des4}
                showLogin={() => setOpenLogin(!openLogin)}
                history={props.history}
                imageClass="smallImage"
            />
          </div>
          <div className="Row">
            <PopularDestinations
                place="New York, United States"
                currency={currency}
                placeId={4349}
                cost="9999"
                url={des5}
                showLogin={() => setOpenLogin(!openLogin)}
                history={props.history}
                imageClass="largeImage"
            />
            <PopularDestinations
                place="Qatar"
                currency={currency}
                placeId={213428}
                cost="9999"
                url={des6}
                showLogin={() => setOpenLogin(!openLogin)}
                history={props.history}
                imageClass="largeImage"
            />
          </div>
          <div className="Row">
            <PopularDestinations
                place="Paris, France"
                currency={currency}
                cost="9999"
                placeId={665191}
                url={des7}
                showLogin={() => setOpenLogin(!openLogin)}
                history={props.history}
                imageClass="smallImage"
            />
            <PopularDestinations
                place="Taipei, Taiwan"
                currency={currency}
                placeId={246729}
                cost="9999"
                url={des8}
                showLogin={() => setOpenLogin(!openLogin)}
                history={props.history}
                imageClass="smallImage"
            />
            <PopularDestinations
                place="Athens, Greece"
                currency={currency}
                placeId={480712}
                cost="9999"
                url={des9}
                showLogin={() => setOpenLogin(!openLogin)}
                history={props.history}
                imageClass="smallImage"
            />
            <PopularDestinations
                place="Istanbul, Turkey"
                placeId={213435}
                currency={currency}
                cost="9999"
                url={des10}
                showLogin={() => setOpenLogin(!openLogin)}
                history={props.history}
                imageClass="smallImage"
            />
          </div>
        </div>
    )
  }

  const DefaultHomeSearchBar = () => {

    const {hasProvider, config : pConfig} = JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV+'_VD_PROVIDER')) ?? {hasProvider:false, config:{}}

    return (
        <div className="HeroCenter">
          {/*<div className="HeroText">*/}
          {/*  Stay Connected <a href='/esim'><img alt={'esim'} style={{marginBottom:'10px', width:'70px'}} src={'esim-b.png'}/></a><br/>*/}
          {/*  Free mobile roaming in 121 countries*/}
          {/*</div>*/}
          <div className="HeroText">
            { "Book your room with best rates + FREE data for roaming ."}
          </div>
          <SearchBar showLogin={() => setOpenLogin(new Date())} history={props.history} />
          <div className="HeroTextBottom">
            We have over 900,000 properties worldwide + FREE data for roaming in 121 countries .
          </div>
        </div>
    )
  }

  let pConfig = {
    name : null,
    textColor : '',
    searchBar : () => <DefaultHomeSearchBar/>,
    content : () => <DefaultHomeContent/>
  };
  switch (provider){
    case 'yoodo':
      pConfig.name = provider;
      pConfig.bCss = {
        backgroundColor : 'rgba(0,0,0,0.5)',
        backgroundImage : `url("${yoodoBg}")`,
        backgroundBlendMode : 'multiply'
      }
      pConfig.textColor= "white";
      pConfig.searchBar = () => <HomeSearchBar setOpenLogin={setOpenLogin} history={props.history}/>
      pConfig.content = () => <HomeContent/>

      localStorage.setItem(process.env.REACT_APP_ENV+'_VD_PROVIDER', JSON.stringify({
        ...JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV+'_VD_PROVIDER')),
        config : pConfig
      }))
      break;
    default:
      break;
  }

  return (
    <div className="Home">
      <div className="Hero" style={{...pConfig.bCss}}>
        <Header
            logoColor={'white'}
            pConfig={pConfig}
            openLoginModal={openLogin}
            // containerStyle={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
            signupColor="white"
        />

        { hasProvider ? pConfig.searchBar() : <DefaultHomeSearchBar/>}
      </div>
      <div>
        { hasProvider ? pConfig.content() : <DefaultHomeContent/>}
      </div>

      <NewsLetter />

      <Footer />
    </div>
  );
};
