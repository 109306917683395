import React, { Component, useState } from "react";
import GoogleMapReact from "google-map-react";
import { MdLocationOn } from 'react-icons/md'
import Axios from "axios";

const AnyReactComponent = ({ text }) => {
  const [hover, sethover] = useState(false);
  return (
    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
      <MdLocationOn
        size={'5rem'}
        color={'#f48247'}
        onMouseOver={() => sethover(true)}
        onMouseOut={() => sethover(false)}
      />
      {hover && (
        <div
          style={{
            width: "max-content",
            color: "#f48247",
            backgroundColor: "white",
            padding: "1rem",
            fontSize: "1.5rem",
            borderRadius:'1rem',
            zIndex:11
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};
const NearByLocation = ({ text, icon, lat, lng }) => {
  const [hover, sethover] = useState(false);
  return (
    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
      <img src={icon}
           width={30}
         onMouseOver={() => sethover(true)}
         onMouseOut={() => sethover(false)}
      />
      {hover && (
        <div
          style={{
            width: "max-content",
            color: "#f48247",
            backgroundColor: "white",
            padding: "1rem",
            fontSize: "1.5rem",
            borderRadius:'1rem',
            zIndex:10
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default class MapContainer extends Component {

  constructor(props) {
    super(props);

    this.apiKey = "AIzaSyDu7Ce9sXCWnLdDRiKcYqbK-4JK4ZwISUE";

    this.state = {
      location : {
        lat : this.props.geoCode.lat,
        lng : this.props.geoCode.long,
      },
      nearBy : []
    }

  }

  // async componentDidMount() {
  //
  //   const lat = this.props.geoCode.lat;
  //   const long = this.props.geoCode.long;
  //   const apiKey = this.apiKey;
  //   const res = await Axios.get(
  //       `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${lat},${long}&radius=30&key=${apiKey}`
  //   ,{
  //         skipInterceptor : {
  //           request :true
  //         }
  //   })
  //
  //   console.log('map-res', res);
  // }

  render() {

    const handleApiLoaded = (map, maps) => {
      const lat = this.props.geoCode.lat;
      const long = this.props.geoCode.long;
      const latLng = new maps.LatLng(lat, long);
      const a = new maps.places.PlacesService(map).nearbySearch({
        location: latLng,
        radius: '1000'
      }, (a, b, c) => {
        const data = a;
        const markers = [];
        data.map(item => {

          if( item.business_status ) {
            markers.push({
              lat: parseFloat(item.geometry.location.lat()),
              lng: parseFloat(item.geometry.location.lng()),
              icon: item.icon,
              name: item.name
            })
          }
        })

        this.setState({nearBy : markers})

      })
    }

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "40vh", width: "100%", minHeight:"300px" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: this.apiKey,
            libraries:['places']
          }}
          layerTypes={['TransitLayer']}
          defaultCenter={{
            lat: Number(this.props.geoCode.lat),
            lng: Number(this.props.geoCode.long),
          }}
          defaultZoom={16}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          <AnyReactComponent
            lat={this.props.geoCode.lat}
            lng={this.props.geoCode.long}
            text={this.props.name}
          />
          {this.state.nearBy && this.state.nearBy.map(item => (
              <NearByLocation
                  lat={item.lat}
                  lng={item.lng}
                  icon={item.icon}
                  text={item.name}
              />
          ))}
        </GoogleMapReact>
      </div>
    );
  }
}
