import React from 'react'
import { Radio } from 'antd';

export const SortBy = (props) => {

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };


  return (
    <Radio.Group onChange={props.onChange} value={props.value}>
        <Radio style={radioStyle} value="ph">
          Highest price first
        </Radio>
        <Radio style={radioStyle} value="sh">
          Highest stars first
        </Radio>
        <Radio style={radioStyle} value="pl">
          Lowest price first
        </Radio>
        <Radio style={radioStyle} value="nr">
          Nearest first
        </Radio>
      </Radio.Group>
  )
}
