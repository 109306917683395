import React, { Component } from "react";
import { Row, Col, Modal, message, Icon } from "antd";
import Navbar2 from "./../components/Navbar1/Navbar2/Navbar2";
import SideMenu from "./../components/Profile/SideMenu";
import UserData from "./../components/Profile/UserData";
import BookingHistory from "./../components/Profile/BookingHistory";
import TransactionHistory from "./../components/Profile/TransactionHistory";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
} from "antd";
// import ReactTelInput from "react-telephone-input";
import ProNavbar from "./profilenavbar";

import "./Profile.css";
import Axios from "axios";
import PhoneInput from "react-phone-input-2";
export default class Profile extends Component {
  constructor(props) {
    super(props);
    if (!localStorage.usertoken) {
      this.props.history.push(`/`);
    }
    this.state = {
      selectedOption: "profile",
      userdata: {},
      visible: false,
      currentpassword: "",
      newpassword: "",
      email: "",
      first_name: "",
      last_name: "",
      contactNumber: "",
      changepasswordvisible: false,
    };
  }
  componentDidMount() {
    const email = localStorage.getItem("useremail");
    Axios.get("/users/userDetails/" + email)
      .then((res) => {
        console.log(res.data);
        this.setState({
          userdata: res.data,
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          email: res.data.email,
          contactNumber: res.data.contactNumber,
        });
      })
      .catch((e) => console.log(e));
  }
  currentpass = (e) => {
    this.setState({ currentpassword: e.target.value });
  };
  newpass = (e) => {
    this.setState({ newpassword: e.target.value });
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    Axios.post("/users/changePassword", {
      email: this.state.userdata.email,
      password: this.state.currentpassword,
      newPassword: this.state.newpassword,
    })
      .then((res) => {
        if (res.data === "Success") {
          message.success("Password updated");
          this.setState({
            visible: false,
            currentpassword: "",
            newPassword: "",
          });
        } else {
          message.error("Wrong Password");
          this.setState({ currentpassword: "", newpassword: "" });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleCancel = (e) => {
    console.log(e);

    this.setState({
      visible: false,
    });
  };
  changeEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  changeFirstName = (e) => {
    this.setState({
      first_name: e.target.value,
    });
  };
  changeLastName = (e) => {
    this.setState({
      last_name: e.target.value,
    });
  };
  changeNumber = (value, data, event, formattedValue) => {
    // console.log('value', value);
    // console.log('value', data);
    // console.log('value', event);
    // console.log('value', formattedValue);
    this.setState({
      contactNumber:value,
    });
  };
  updateUser = () => {
    Axios.put("/users/editUser", {
      prevemail: this.state.userdata.email,
      email: this.state.email,
      firstName: this.state.first_name,
      lastName: this.state.last_name,
      contactNumber: this.state.contactNumber,
    })
      .then((res) => {
        if (res.data.status == "success") {
          message.success("Profile is updated");
          let data = res.data.userdata;
          this.setState({
            userdata: data,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            contactNumber: data.contactNumber,
          });
          localStorage.setItem("useremail", data.email);
          localStorage.setItem("userdata", JSON.stringify({
            first_name :data.firstName,
            last_name:data.lastName,
            contactNumber:data.contactNumber,
          }));
        } else {
          let data = this.state.userdata;
          message.error("Fail to update profile");
          this.setState({
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            contactNumber: data.contactNumber,
          });
        }
      })
      .catch((e) => console.log(e));
  };
  render = () => {


    return (
      <div className="profileouterContainer">
        <div style={{ fontSize: "2rem", marginBottom: "3rem" }}>Profile</div>
        <div className="profileContainer">
          <div>
            <Form className="columnContainer">
              {/*<div className="inputContainer">*/}
              {/*  <div className="inputDescription">Title</div>*/}
              {/*  <Form.Item style={{ margin: 0 }}>*/}
              {/*    <Select*/}
              {/*      style={{ width: 100, border: "none" }}*/}
              {/*      defaultValue="Mr"*/}
              {/*    >*/}
              {/*      <Select.Option value="Mr">Mr.</Select.Option>*/}
              {/*      <Select.Option value="Ms">Ms.</Select.Option>*/}
              {/*    </Select>*/}
              {/*  </Form.Item>*/}
              {/*</div>*/}

              <div className="inputContainer">
                <div className="inputDescription">First Name</div>
                <Form.Item style={{ margin: 0 }}>
                  <Input
                    style={{ border: "none", width: "100%" }}
                    value={this.state.first_name}
                    onChange={this.changeFirstName}
                  />
                </Form.Item>
              </div>

              <div className="inputContainer">
                <div className="inputDescription">Last Name</div>
                <Form.Item style={{ margin: 0 }}>
                  <Input
                    style={{ border: "none", width: "100%" }}
                    value={this.state.last_name}
                    onChange={this.changeLastName}
                  />
                </Form.Item>
              </div>

              <div className="inputContainer">
                <div className="inputDescription">Phone</div>
                <Form.Item style={{ margin: 0 }}>
                <PhoneInput onChange={this.changeNumber} inputClass="profiletelephoneinput" containerClass="profiletelephoneinput" placeholder="" value={this.state.contactNumber}/>
                </Form.Item>
              </div>

              <div className="inputContainer">
                <div className="inputDescription">Email</div>
                <Form.Item style={{ margin: 0 }}>
                  <Input
                    style={{ border: "none", width: "100%" }}
                    value={this.state.email}
                    onChange={this.changeEmail}
                  />
                </Form.Item>
              </div>

              <Form.Item style={{ margin: 0 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={this.updateUser}
                  style={{ marginRight: "auto" }}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>

          <div>
            <Form className="columnContainer">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    changepasswordvisible: !this.state.changepasswordvisible,
                  })
                }
								style={{ padding: "0", marginBottom: "2rem", color: "#f48148" }}
              >
                Change Password{" "}
                {this.state.changepasswordvisible ? (
                  <Icon type="up" />
                ) : (
                  <Icon type="down" />
                )}
              </Button>
              {this.state.changepasswordvisible && (
                <div>
                  <div className="inputContainer">
                    <div className="inputDescription">Current Password</div>
                    <Form.Item style={{ margin: 0 }}>
                      <Input
                        style={{ border: "none", width: "100%" }}
                        value={this.state.currentpassword}
                        onChange={this.currentpass}
                        type="password"
                      />
                    </Form.Item>
                  </div>

                  <div className="inputContainer">
                    <div className="inputDescription">New Password</div>
                    <Form.Item style={{ margin: 0 }}>
                      <Input
                        style={{ border: "none", width: "100%" }}
                        value={this.state.newpassword}
                        onChange={this.newpass}
                        type="password"
                      />
                    </Form.Item>
                  </div>

                  <Button
                    type="primary"
                    onClick={this.handleOk}
                  >
                    Change password
                  </Button>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    );
  };
}
