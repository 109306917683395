import React from "react";
import { Checkbox, Row } from "antd";

export const AccommodationType = (props) => {
  const {
    hotelType,
    filtersObject,
    checkboxPopular,
    setcheckboxPopular,
  } = props;

  let filters = Object.keys(hotelType).map((type) => (
    <Row style={{ margin: "1rem 0" }} key={type}>
      <Checkbox style={{width:"100%"}} value={type}>
        <span>{type}</span>
        <span style={{ float: "right" }}>
          {hotelType[type] ? `(${hotelType[type]})` : ""}
        </span>
      </Checkbox>
    </Row>
  ));
  // freeBreakfast
  Object.keys(filtersObject).forEach((type) => {
    if (type === "freeBreakfast")
      filters.push(
        <Row style={{ margin: "1rem 0" }} key={type}>
          <Checkbox style={{width:"100%"}} value={type}>
            <span>Free Breakfast</span>
            <span style={{ float: "right" }}>
              {filtersObject.freeBreakfast
                ? `(${filtersObject.freeBreakfast})`
                : ""}
            </span>
          </Checkbox>
        </Row>
      );
    if (type === "payAtHotel")
      filters.push(
        <Row style={{ margin: "1rem 0" }} key={type}>
          <Checkbox style={{width:"100%"}} value={type}>
            <span>Pay at Hotel</span>
            <span style={{ float: "right" }}>
              {filtersObject.payAtHotel ? `(${filtersObject.payAtHotel})` : ""}
            </span>
          </Checkbox>
        </Row>
      );

    if (type === "refundable")
      filters.push(
          <Row style={{ margin: "1rem 0" }} key={type}>
            <Checkbox style={{width:"100%"}} value={type}>
              <span>Refundable</span>
              <span style={{ float: "right" }}>
              {filtersObject.refundable ? `(${filtersObject.refundable})` : ""}
            </span>
            </Checkbox>
          </Row>
      );
  });

  return (
    <div>
      <Checkbox.Group
        value={checkboxPopular}
        style={{ width: "100%" }}
        onChange={setcheckboxPopular}
      >
        {filters}
      </Checkbox.Group>
    </div>
  );
};
