import React from "react";
import NumericInput from "react-numeric-input";
import './SearchBar.css'
import './SearchBarRoomInfo.css'

const RoomRow = (props) => {
  return (
    <div className="RoomRow">
      <div className="leftinfo">
        <div className="main">{props.info}</div>
        <div className="extra">{props.subinfo}</div>
      </div>

      <NumericInput
        defaultValue={props.defaultValue !== null? props.defaultValue: 0}
        min={props.min !== null? props.min: 0}
        max={props.max !== null? props.max: 10}
        mobile={true}
        onChange={props.changeInputValue}
        value={props.value}
        style={{
          input: {
            width: "10rem",
            borderColor: "#E9EDEF",
            height: "3rem",
          },
          btn: {
            border: "none",
          },
          btnUp: {
            background: "white",
          },
          btnDown: {
            background: "white",
          },
          plus: {
            background: "rgba(217, 59, 79, 1)",
          },
          minus: {
            background: "rgba(217, 59, 79, 1)",
          },
        }}
      />
    </div>
  );
};

export default RoomRow;
