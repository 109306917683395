import React, {useState, useEffect} from "react";
import { HomeDesktop } from "./HomeDesktop";
import MediaQuery from "react-responsive";
import { HomeMobile } from "./HomeMobile";
import {_gtag} from "../../Helper";

export const Home = (props) => {

  localStorage.setItem(process.env.REACT_APP_ENV+'_VD_CORRE', null)

  _gtag(`landing_screen`);

  // const [hasProvider, setHasProvider] = useState(false);
  // const [provider, setProvider] = useState(null);
  const [generalProps, setGeneralProps] = useState({
    history : props.history,
    hasProvider : false,
    provider: null
  });


  useEffect(() => {

    const path = props.history.location.pathname.toString().toLowerCase();

    switch (path){
      case '/yoodo':
        setGeneralProps({
          ...generalProps,
          hasProvider : true,
          provider : 'yoodo'
        })
        localStorage.setItem(process.env.REACT_APP_ENV+'_VD_PROVIDER', JSON.stringify({
          hasProvider : true,
          provider : 'yoodo'
        }))
        break;
      default:
        setGeneralProps({
          ...generalProps,
          hasProvider : false,
          provider : null
        })
        localStorage.setItem(process.env.REACT_APP_ENV+'_VD_PROVIDER', JSON.stringify({
          hasProvider : false,
          provider : null
        }))
        break;
    }
  }, []);




  return (
    <div>
      <MediaQuery maxWidth={775}>
        <HomeMobile {...generalProps} />
      </MediaQuery>
      <MediaQuery minWidth={776}>
        <HomeDesktop {...generalProps} />
      </MediaQuery>
    </div>
  );
};
