import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import setupAxios from "./AxiosHelper";
import {getStorage, setStorage} from "./LocalStorageHelpers";
import Axios from "axios";
// import { RoomProvider } from './Context'

setupAxios();

const getIpAddress = () =>{
  const ip = getStorage('_customer_ip')

  if( typeof ip != 'undefined' && ip != 'null' && ip != null && ip != 'undefined' && ip != '') return;

  Axios.get('https://api.ipify.org')
      .then((res) => setStorage('_customer_ip', res.data, 86400))
}

getIpAddress();

ReactDOM.render(<Router><App /></Router>

    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
