import React, {useContext} from 'react'
import { Link } from "react-router-dom";
import {FaFacebookSquare, FaInstagram, FaWhatsapp, FaWhatsappSquare} from "react-icons/fa";
import { Select } from "antd";
import VDContext from "../../VDContext";
const { Option } = Select;
export const FooterDesktop = ({showEsim=true, ...props}) => {

  // const context = useContext(VDContext);
  // console.log('context', context)

  const date = new Date();

  return (
    <div className="homefooter">
        <div className="LPFooter">
          <div className="FooterColumn">

            <span>
              <Link to="/aboutus">About Us</Link>
            </span>
            <span>
              <Link to="/ContactUs">Contact Us</Link>
            </span>
            { showEsim &&
            <span>
              <Link to="/esim">eSIM</Link>
            </span>
            }

          </div>

          <div className="FooterColumn">
            <span>
              <Link to="/termsandconditions">Terms & Conditions</Link>
            </span>
            {/*<span>*/}
            {/*  <Link to="/esim-tnc">E-Sim Terms & Conditions</Link>*/}
            {/*</span>*/}
            <span>
              <Link to="/privacypolicy">Privacy Policy</Link>
            </span>
            <span>
              <Link to="/faq">FAQ</Link>
            </span>
          </div>

          <div className="FooterColumn">
          <span>
              Currency
            </span>
            <div>
                <Select
                  value={props.currency}
                  onChange={(value)=>{props.changeCurrency(value)}}
                >
                <Option key="MYR">MYR</Option>
                <Option key="USD">USD</Option>
                <Option key="EUR">EUR</Option>
                <Option key="INR">INR</Option>
                <Option key="AED">AED</Option>
                <Option key="PHP">PHP</Option>
                <Option key="BHD">BHD</Option>
                <Option key="SAR">SAR</Option>
                <Option key="OMR">OMR</Option>
                <Option key="QAR">QAR</Option>
                </Select>
              </div>
          </div>


          <div className="FooterColumn"></div>


          <div className="FooterColumn" >

            <div className="footersocial">
            <span style={{fontSize:"1.1rem"}}>Follow us on Social Media </span>
              {/*<a style={{color: 'green'}} target="_blank"  href="https://wa.link/wixktq">*/}
              {/*  <FaWhatsapp/>*/}
              {/*</a>*/}
              <a target="_blank"  href="https://www.facebook.com/visitorsdeals">
                <FaFacebookSquare />
              </a>
              <a target="_blank"  href="https://www.instagram.com/visitors_deals">
                <FaInstagram />
              </a>
            </div>

          </div>


          {/* <div
            className="FooterColumn"
            style={{
              alignSelf: "flex-end",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span style={{ margin: "0 2rem" }}>
              Ready to get deals? Lets get started!{" "}
            </span>
            <Button
              type="primary"
              style={{
                backgroundColor: "rgba(244, 130, 71,0.9)",
                color: "white",
                border: "none",
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <MdMail style={{color:'white', marginRight: '1rem'}} />
              Subscribe to our Newsletter
            </Button>
          </div> */}
          {/* <div className="FooterColumn" style={{alignSelf: 'flex-end'}}>

          </div> */}
        </div>



        <div className="LowerFooter">
          <span style={{margin:'1rem 1rem'}}>
            Copyright © 2020 - {date.getFullYear()}. Visitors Deals. All Rights Reserved ({process.env.REACT_APP_VERSION})
          </span>


        </div>
      </div>
  )
}
