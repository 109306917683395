import React from "react";
import { Link } from "react-router-dom";
import {FaFacebookSquare, FaInstagram, FaWhatsapp} from "react-icons/fa";
import { Select } from "antd";
import "../Footer.css";

const { Option } = Select;
export const FooterMobile = ({showEsim, ...props}) => {
  return (
    <div className="FooterMobile">
      <div className="logo" />
      <div>
        <Link to="aboutus">About Us</Link>
      </div>
      <div>
        <Link to="contactus">Contact Us</Link>
      </div>
      { showEsim && (
        <div>
          <Link to="esim">eSIM</Link>
        < /div>
      )}
      <div>
        <Link to="termsandconditions">Terms and Conditions</Link>
      </div>
      <div>
        <Link to="privacypolicy">Privacy Policy</Link>
      </div>
      <div>
        <Link to="/faq">FAQ</Link>
      </div>

      <div className="currency">
        <span>Currency</span>
        <div style={{marginLeft:'10px'}}>
          <Select
            value={props.currency}
            onChange={(value) => {
              props.changeCurrency(value);
            }}
          >
            <Option key="MYR">MYR</Option>
            <Option key="USD">USD</Option>
            <Option key="EUR">EUR</Option>
            <Option key="INR">INR</Option>
            <Option key="AED">AED</Option>
            <Option key="PHP">PHP</Option>
            <Option key="BHD">BHD</Option>
            <Option key="SAR">SAR</Option>
            <Option key="OMR">OMR</Option>
            <Option key="QAR">QAR</Option>
          </Select>
        </div>
      </div>
      <div>Follow Us on social media</div>
      <div className="footersocial">
        {/*<a style={{color: 'green'}} target="_blank"  href="https://wa.link/wixktq">*/}
        {/*  <FaWhatsapp/>*/}
        {/*</a>*/}
        <a target="_blank"  href="https://www.facebook.com/visitorsdeals" style={{fontSize:'40px'}}>
          <FaFacebookSquare />
        </a>
        <a target="_blank"  href="https://www.instagram.com/visitors_deals" style={{fontSize:'40px', marginLeft:'15px'}}>
          <FaInstagram />
        </a>
      </div>
    </div>
  );
};
