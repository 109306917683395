import React from "react";
import { Checkbox, Row } from "antd";

export const StarRating = (props) => {
  const { filtersObject, checkboxStars, setcheckboxStars } = props;

  return (
    <Checkbox.Group
      value={checkboxStars}
      style={{ width: "100%" }}
      onChange={setcheckboxStars}
    >
      <Row style={{ margin: "1rem 0" }}>
        <Checkbox style={{ color: "#d93b4f", width:"100%" }} value="star5">
          <span>★ ★ ★ ★ ★ </span>
          <span style={{ float: "right" }}>
            {filtersObject.star5 ? `(${filtersObject.star5})` : "(0)"}
          </span>
        </Checkbox>
      </Row>
      <Row style={{ margin: "1rem 0" }}>
        <Checkbox style={{ color: "#d93b4f", width:"100%" }} value="star4">
          <span>★ ★ ★ ★ </span>
          <span style={{ float: "right" }}>
            {filtersObject.star4 ? `(${filtersObject.star4})` : "(0)"}
          </span>
        </Checkbox>
      </Row>
      <Row style={{ margin: "1rem 0" }}>
        <Checkbox style={{ color: "#d93b4f", width:"100%" }} value="star3">
          <span>★ ★ ★ </span>
          <span style={{ float: "right" }}>
            {filtersObject.star3 ? `(${filtersObject.star3})` : "(0)"}
          </span>
        </Checkbox>
      </Row>
      <Row style={{ margin: "1rem 0" }}>
        <Checkbox style={{ color: "#d93b4f", width:"100%" }} value="star2">
          <span>★ ★ </span>
          <span style={{ float: "right" }}>
            {filtersObject.star2 ? `(${filtersObject.star2})` : "(0)"}
          </span>
        </Checkbox>
      </Row>
      <Row style={{ margin: "1rem 0" }}>
        <Checkbox style={{ color: "#d93b4f", width:"100%" }} value="star1">
          <span>★ </span>
          <span style={{ float: "right" }}>
            {filtersObject.star1 ? `(${filtersObject.star1})` : "(0)"}
          </span>
        </Checkbox>
      </Row>
    </Checkbox.Group>
  );
};
