import React from "react";
import "./LegalDocuments.css";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";

const PrivacyPolicy = () => {
  return (
    <div className="docsPage" style={{ height: "auto" }}>
      <Header />
      <div className="docsContainer">
        <span className="docsHeading">Privacy Policy</span>
        <p className="docsPara">
          <div className="docsSubHeading">
            Collection of Personal Information
          </div>
          Visitors Deals only collects your Personal Information with the
          consent of the member. Collection is done through the registration
          process when you are requested to provide information that identifies
          you. This information includes (but is not necessarily limited to):
          your first name, your last name and your email address. Visitors Deals
          will only collect your Personal Information from you (i.e. not from
          any other person or source), by lawful and fair means and not in an
          unreasonably intrusive way.
          <div className="docsSubHeading">Use of Personal Information</div>
          We do not sell or rent your personal data, all data are collected to
          enable a transaction and communication purposes’ only , to complete
          the booking we need to transfer your relevant details name , contact
          details , payment details & etc. to the Service Providers ( hotel ) or
          3rd parties .<div className="docsSubHeading">Data Security</div>
          As for the security, we make sure only authorized team members of
          Visitors Deals can access to the data and all the times we will use
          the appropriate business systems and protocols to safeguard your
          personal data.
          <div className="docsSubHeading">Disclosure of Information</div>
          Visitors Deals will only disclose your Personal Information to a third
          party:
          <br />
          a. If you have consented
          <br />
          b. If we are required, by law, to make the disclosure
          <br />
          c. Visitors Deals does not disclose your Personal Information to any
          unrelated party for the purposes of direct marketing
          <br />
          <div className="docsSubHeading">Access</div>
          You have a right to request access to your Personal Information that
          is stored by us. You also have a right to request that we permanently
          delete Personal Information of yours that we store. You may do this by
          sending us an email to the following address:
          contactus@visitorsdeals.com
          <div className="docsSubHeading">Express Consent</div>
          Visitors Deals only collect Personal Information of individuals who
          have expressly consented to receive our emails by subscribing to
          Visitors Deals. Visitors Deals’ policy is not to add individuals to
          our database when their express consent has not been obtained. If you
          wish to 'refer a friend' to Visitors Deals that friend must register
          themselves - we will not enter Personal Information on their behalf.
          <div className="docsSubHeading">Unsubscribe Facility</div>
          Every email that Visitors Deals sends contains an unsubscribe facility
          on the bottom of the page. If you wish to unsubscribe from our emails,
          you can do so immediately by clicking on the 'Unsubscribe' button and
          following the relevant prompts. You may also email an 'unsubscribe
          request' to: contact@visitorsdeals. We endeavor to process all
          'unsubscribe emails' as soon as practicable. In any event, all such
          requests will be actioned within five (5) business days. The quickest
          and simplest way to unsubscribe is by clicking the 'Unsubscribe'
          button.
          <div className="docsSubHeading">Changes to Policy</div>
          Visitors Deals may change this Policy at any time at our absolute
          discretion by posting the revised policy on this Website. It is your
          responsibility to review the terms of this Policy regularly. Your
          continued receipt of our daily emails will constitute acceptance and
          acknowledgment of the terms of this Policy.
          <div className="docsSubHeading">Cookies</div>
          We use cookies :
          <br />
          i .To show the website and functions properly as per your device you
          are using .
          <br />
          ii .To remember your visit to our site and all the preferences you
          have selected like , currency , language , and registration
          information .
          <br />
          iii .To find out how are you using our website and to improve your
          user experience .
          <br />
          <div className="docsSubHeading">Contact information</div>
          Any feedback or questions regarding this Policy should be emailed
          to contactus@visitorsdeals.com and addressed to the administrator .
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
