import React, { useState } from "react";
import { Button } from "antd";
import "./Landmark.css";

const Landmarks = (props) => {

  const [more, setmore] = useState(false)

  const handleClick = () => setmore(!more)
  if (props.nearByAttractions.length === 0) {
    return "";
  }

  let landmarks = more? props.nearByAttractions : props.nearByAttractions.slice(0, props.number);

  return (
    <div>
      <div className="LandmarkContainer">
        {landmarks.map((landmark) => (
          <div className="Landmarks" key={landmark.name}>
            <div>{landmark.name}</div>
            <div style={{ fontWeight: "bold" }}>
              {landmark.distance + landmark.unit}
            </div>
          </div>
        ))}
      </div>
      <Button type="link" onClick={handleClick} style={{padding: '0',color:"#F48247"}}>
          {more ? "View less" : "View More"}
        </Button>
    </div>
  );
};

export default Landmarks;
