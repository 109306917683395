import React from "react";
import SearchBar from "../../components/SearchBar/SearchBar";
import { DesktopSkeleton } from "../../components/Skeleton/DesktopSkeleton";
import { Dropdown, Button, Icon, Input } from "antd";
import "./SearchResults.css";

const menuStyle = {
  backgroundColor: "#fff",
  width: "18vw",
  padding: "0 1rem",
  borderRadius: "5px",
  border: "1px solid #E9EDEF",
};

const containerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "1.5rem",
};
export const SearchResultsDesktop = (props) => {
  // const { loading } = props;
  // if (loading) {
  //   return (
  //     <div className="SearchResultsDesktop">
  //       <DesktopSkeleton />
  //     </div>
  //   );
  // }

  const { place, startDate, endDate, noofroom, hasProvider } = props.location;
  const { hotels, history, totalHotels, hotelsLoading } = props;

  if (hotelsLoading) {
    return (
      <div className="SearchResultsDesktop">
        <DesktopSkeleton />
      </div>
    );
  }

  const days = Math.round(
    (Date.parse(endDate) - Date.parse(startDate)) / (1000 * 60 * 60 * 24)
  );

  const changeSearchTerm = (event) => {
    props.setCurrentPage(1);
    props.setHotelNameSearch(event.target.value);
  };

  document.body.style.overflow = "auto";
  return (
    <div className="SearchResultsDesktop">
      <div style={{width:"75vw", margin:'30px 0px'}}>
        <div style={{ width:"80%", fontSize:'3rem', fontWeight:'700'}}>
          {`Book with us for best room rates in ${place} `} {!hasProvider && `+ FREE data for roaming up to 1GB. DAILY`}
        </div>
      </div>

      <div style={{ margin: "4rem 0 0 0" }}>
        <SearchBar
            history={history}
            place={props.location.place}
            startDate={props.location.startDate}
            endDate={props.location.endDate}
            panes={props.location.panes[0]}
            totalAdults={props.location.noofAdults}
            totalChildren={props.location.noofChild}
            totalRooms={props.location.noofroom}
        />
      </div>

      <div
        className="searchHeading"
        style={{ fontSize: "2rem", fontWeight: "bold", marginTop: "2rem" }}
      >
        {!hotelsLoading
          ? `${totalHotels} results found for ${place}`
          : `Looking for hotels at ${place}`}
      </div>
      <div style={{marginBottom:'20px'}}>
        Price for{" "}
        <b>
          {days}
          {days >= 2 ? " nights " : " night "}, {noofroom}
          {noofroom >= 2 ? " rooms " : " room "}{" "}
        </b>{" "}
        (tax and charges included)
      </div>

      <div style={containerStyle}>
        <Input
          type="text"
          placeholder="Search Hotel Name"
          style={{ width: "18vw" }}
          value={props.HotelNameSearch}
          onChange={changeSearchTerm}
        />
        <Dropdown
          overlay={
            <div style={{ ...menuStyle, padding: "1rem" }}>{props.slider}</div>
          }
        >
          <Button style={{ width: "18vw" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Price range</div>
              <div>
                <Icon type="down" />
              </div>
            </div>
          </Button>
        </Dropdown>
        <Dropdown
          overlay={
            <div style={menuStyle}>
              {
                <>
                  Star Ratings
                  {props.StarRatingFilters}
                  <hr />
                  Accommodation Type
                  {props.AccommodationTypeFilters}
                </>
              }
            </div>
          }
        >
          <Button style={{ width: "18vw" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Filters</div>
              <div>
                <Icon type="down" />
              </div>
            </div>
          </Button>
        </Dropdown>
        {/* <Dropdown
          overlay={<div style={menuStyle}>{props.StarRatingFilters}</div>}
        >
          <Button style={{ width: "15vw" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Star ratings</div>
              <div>
                <Icon type="down" />
              </div>
            </div>
          </Button>
        </Dropdown> */}
        <Dropdown
          overlay={
            <div style={{ ...menuStyle, padding: "1rem" }}>{props.sortBy}</div>
          }
        >
          <Button style={{ width: "18vw" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Sort by</div>
              <div>
                <Icon type="down" />
              </div>
            </div>
          </Button>
        </Dropdown>
      </div>

        <div className="SearchContainer">{hotels}</div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {props.paging}
        </div>
      </div>
  );
};
