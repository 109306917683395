import FAQ from "../components/FAQ";
import React from "react";
import './FAQGeneral.css';
import Header from "../components/Header/Header";
import Footer from "../components/Footer";

const FAQGeneral = () => {



  const BookingDetailFaq = [
    {
      Q : "When do I get my booking  confirmation email?",
      A : "The moment your booking is confirmed immediately you will see your booking confirmation appear on your screen ( Pop Up ) ."+
          "</br>" +
          "You will receive a booking confirmation email with an attachment (PDF file) within 1 hour of booking , please  check your junk / spam folders If you still haven't received it after 1 hours time."
    },
    {
      Q : "Can I log in and check my booking details / confirmation?",
      A : "Yes you  can always view your booking details and status online" +
          " </br>" +
          " </br>" +
          "To see your booking confirmation : \n" +
          " </br>" +
          "Please log in > select  MY ACCOUNT   > click on BOOKING , you can see your booking confirmation there .\n" +
          " </br>" +
          " </br>" +
          "If you are unable to locate your booking confirmation email or can’t locate your booking in my account in a few hour’s time , please feel free to contact us .\n"
    }
  ];

  const CancelRefund = [
    {
      Q : "How can I cancel my booking?",
      A : "You can cancel your booking by reaching out to our customer service through a call, chat or email."
    },
    {
      Q : "Will any cancellation fee be charged ?",
      A : "Cancellation fees , policies , terms & conditions are set by the properties , some bookings come with free cancellation, you can cancel the booking and get full refund  , for some bookings you might incur a cancellation fee ,  for non – refundable bookings you won’t get any refund ."
    },
    {
      Q : "How do I know if my booking was cancelled?",
      A : "Once you inform us about your cancellation , we will cancel your booking and send you a cancellation confirmation email ."
    },
    {
      Q : "Where can I find the cancellation policy?",
      A : "Once you have your preferred room , please click > <strong>SELECT > VIEW POLICY</strong> , here you can find the cancellation  policy for your booking ."
    },
    {
      Q : "How long will it take to get a refund ?",
      A : "We will process refunds immediately , for the funds to be credited back into your account it might take anywhere around 5 – 15 working days ( these days are estimated , it all depends on the individual banks )."
    },
  ];

  const pricePayment = [
    {
      Q : "What payment methods are accepted?",
      A : "For the time being  we are only accepting payments through credit cards , we are working towards accepting more payment methods in the near future ."
    },
    {
      Q : "I’ve been charged , but I don’t see or receive any booking confirmation ?",
      A : "Please reach us via our live chat or write to us ."
    },
    {
      Q : "Are the stated prices per person or per room?",
      A : "All published prices are per room basis & it is for the entire length of the stay the total days booked , unless stated otherwise ."
    },
    {
      Q : "Are taxes included in the price?",
      A : "Taxes are included  in the price , please check the individual properties terms & conditions as some properties might charge and collect from you personally local taxes , resort fee , tourism tax & etc"
    },
    {
      Q : "Where is  the payment policy for my booking?",
      A : "Once you have your preferred room , please click > <strong>SELECT > VIEW POLICY</strong>, here you can find the payment policy for your booking ."
    },
    {
      Q : "Early check- in , Late check-out ,  Child policies , Pets , Smoking & others",
      A : "Please read all the above policies at the individual room or property page or at times under the House Rules ."
    },
    {
      Q : "Can I make changes to my booking?",
      A : "Yes changes are allowed , but changes to any booking is depending on the property's policies , please make sure you read the terms & conditions .In the event if you need any changes you can reach out to the property ."
    },
    {
      Q : "What do non-refundable and free cancellation mean?",
      A : "A <strong>non-refundable</strong> policy means you will pay the full price if you cancel your booking or if you don’t show up, or at times you will be allowed to make changes to your  booking with  a small fee ." +
          "</br>" +
          "</br>" +
          "A <strong>free cancellation</strong> policy allows you to change or cancel a booking  without any extra charges , when you do it within a specified time frame .\n" +
          "</br>" +
          "</br>" +
          "Room & property policies are set by individual properties , therefore all properties will have different policies to a certain degree , please ensure you read and understand the room or property policies , rules before you place a booking with us .\n"
    },
  ];

  const RoomType = [
    {
      Q : 'Type of Rooms',
      A : "          <ul className='room_type'>\n" +
          "            <li>\n" +
          "              <strong>Single : </strong>\n" +
          "              A room assigned to one person. May have one or more beds.\n" +
          "            </li>\n" +
          "            <li>\n" +
          "              <strong>Double : </strong>\n" +
          "              A room assigned to two people. May have one or more beds.\n" +
          "            </li>\n" +
          "            <li>\n" +
          "              <strong>Twin : </strong>\n" +
          "              A room with two twin beds. May be occupied by one or more people.\n" +
          "            </li>\n" +
          "            <li>\n" +
          "              <strong>Triple : </strong>\n" +
          "              A room that can accommodate three persons and has been fitted with three twin beds, one double bed and one twin bed or two double beds.\n" +
          "            </li>\n" +
          "            <li>\n" +
          "              <strong>Quad : </strong>\n" +
          "              A room assigned to four people. May have two or more beds.\n" +
          "            </li>\n" +
          "            <li>\n" +
          "              <strong>Queen : </strong>\n" +
          "              A room with a queen-sized bed. May be occupied by one or more people.\n" +
          "            </li>\n" +
          "            <li>\n" +
          "              <strong>King : </strong>\n" +
          "              A room with a king-sized bed. May be occupied by one or more people.\n" +
          "            </li>\n" +
          "            <li>\n" +
          "              <strong>Double-double : </strong>\n" +
          "              A Room with two double ( or perhaps queen) beds. And can accommodate two to four persons with two twin, double or queen-size beds.\n" +
          "            </li>\n" +
          "            <li>\n" +
          "              <strong>Studio : </strong>\n" +
          "              A room with a studio bed- a couch which can be converted into a bed. May also have an additional bed.\n" +
          "            </li>\n" +
          "            <li>\n" +
          "              <strong>Suite / Executive Suite : </strong>\n" +
          "              A parlour or living room connected to one or more bedrooms. (A room with one or more bedrooms and a separate living space.)\n" +
          "            </li>\n" +
          "            <li>\n" +
          "              <strong>Mini Suite or Junior Suite : </strong>\n" +
          "              A single room with a bed and sitting area. Sometimes the sleeping area is in a bedroom separate from the parlour or living room.\n" +
          "            </li>\n" +
          "            <li>\n" +
          "              <strong>Cabana: </strong>\n" +
          "              This type of room is always adjoining to the swimming pool or has a private pool attached to the room.\n" +
          "            </li>\n" +
          "            <li>\n" +
          "              <strong>President Suite | Presidential Suite: </strong>\n" +
          "              The most expensive room provided by a hotel. Usually, only one presidential suite is available in one single hotel property. Similar to the normal suites, a president suite always has one or more bedrooms and a living space with a strong emphasis on grand in-room decoration, high-quality amenities and supplies, and tailor-made services (e.g. personal butler during the stay).\n" +
          "            </li>\n" +
          "          </ul>\n"
    }
  ];
  return (
      <div className="docsPage" style={{ height: "auto" }}>
        <Header />
        <div className="docsContainer">
          <div className="docsHeading">Visitordeals FAQ</div>
          <div className="docsSubHeading" style={{fontWeight:"bold"}}>Booking Detail</div>
          <FAQ defaultOpen={[]}>

            {BookingDetailFaq.map((item,index) =>(
                <FAQ.QAItem>
                  <FAQ.Question  answerId={`q${index}`}>
                    {(isOpen, onToggle) => {
                      return (<div style={{borderRadius:10, border:'1px solid #E9EDEF', padding:20, color:'black'}}>
                          <span style={{fontWeight:'600', fontSize:'16px'}}>
                            <span style={{color:"#EB234A", fontSize:25, marginRight:20}}>{isOpen ? "-" : "+"}</span>
                            {item.Q}
                          </span>
                      </div>);
                    }}
                  </FAQ.Question>
                  <FAQ.Answer id={`q${index}`}>
                    <div style={{marginTop:'5px', backgroundColor:'#dddddd45', marginLeft:'20px', padding:'20px', borderRadius:'5px'}}>
                      <div dangerouslySetInnerHTML={{ __html:item.A}} />
                    </div>
                  </FAQ.Answer>
                </FAQ.QAItem>
            ))}
          </FAQ>
          <div className="docsSubHeading" style={{fontWeight:"bold"}}>Cancellations & Refund</div>
          <FAQ defaultOpen={[]}>
            {CancelRefund.map((item,index) =>(
                <FAQ.QAItem>
                  <FAQ.Question  answerId={`q${index}`}>
                    {(isOpen, onToggle) => {
                      return (<div style={{borderRadius:10, border:'1px solid #E9EDEF', padding:20, color:'black'}}>
                          <span style={{fontWeight:'600', fontSize:'16px'}}>
                            <span style={{color:"#EB234A", fontSize:25, marginRight:20}}>{isOpen ? "-" : "+"}</span>
                            {item.Q}
                          </span>
                      </div>);
                    }}
                  </FAQ.Question>
                  <FAQ.Answer id={`q${index}`}>
                    <div style={{marginTop:'5px', backgroundColor:'#dddddd45', marginLeft:'20px', padding:'20px', borderRadius:'5px'}}>
                      <div dangerouslySetInnerHTML={{ __html:item.A}} />
                    </div>
                  </FAQ.Answer>
                </FAQ.QAItem>
            ))}
          </FAQ>
          <div className="docsSubHeading" style={{fontWeight:"bold"}}>Pricing & Payment</div>
          <FAQ defaultOpen={[]}>
            {pricePayment.map((item,index) =>(
                <FAQ.QAItem>
                  <FAQ.Question  answerId={`q${index}`}>
                    {(isOpen, onToggle) => {
                      return (<div style={{borderRadius:10, border:'1px solid #E9EDEF', padding:20, color:'black'}}>
                          <span style={{fontWeight:'600', fontSize:'16px'}}>
                            <span style={{color:"#EB234A", fontSize:25, marginRight:20}}>{isOpen ? "-" : "+"}</span>
                            {item.Q}
                          </span>
                      </div>);
                    }}
                  </FAQ.Question>
                  <FAQ.Answer id={`q${index}`}>
                    <div style={{marginTop:'5px', backgroundColor:'#dddddd45', marginLeft:'20px', padding:'20px', borderRadius:'5px'}}>
                      <div dangerouslySetInnerHTML={{ __html:item.A}} />
                    </div>
                  </FAQ.Answer>
                </FAQ.QAItem>
            ))}
          </FAQ>
          <div className="docsSubHeading" style={{fontWeight:"bold"}}>Room Details:</div>
          <FAQ defaultOpen={[]}>
            {RoomType.map((item,index) =>(
                <FAQ.QAItem>
                  <FAQ.Question  answerId={`q${index}`}>
                    {(isOpen, onToggle) => {
                      return (<div style={{borderRadius:10, border:'1px solid #E9EDEF', padding:20, color:'black'}}>
                          <span style={{fontWeight:'600', fontSize:'16px'}}>
                            <span style={{color:"#EB234A", fontSize:25, marginRight:20}}>{isOpen ? "-" : "+"}</span>
                            {item.Q}
                          </span>
                      </div>);
                    }}
                  </FAQ.Question>
                  <FAQ.Answer id={`q${index}`}>
                    <div style={{marginTop:'5px', backgroundColor:'#dddddd45', marginLeft:'20px', padding:'20px', borderRadius:'5px'}}>
                      <div dangerouslySetInnerHTML={{ __html:item.A}} />
                    </div>
                  </FAQ.Answer>
                </FAQ.QAItem>
            ))}
          </FAQ>
        </div>
        <Footer />
      </div>
  )
}

export default FAQGeneral;
