import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input } from 'antd'
import Axios from 'axios'
import '../../pages/LandingPage.css';
import {processAutoSugestionResponse} from "../../Helper";

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: this.props.activeSuggestion ?? 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: this.props.place?this.props.place:"",

      onChangeTimeout : null
    };
  }


  onChange = userInput => {

    const url = process.env.REACT_APP_PROVIDER_AUTOSUGEST_URL;

    if( userInput.length <= 1 ){
      this.setState({
        activeSuggestion: 0,
        filteredSuggestions:[],
        showSuggestions: true,
      });
    }


    let res = Axios.get(`${url}/locations/locationcontent/autosuggest?size=30&term=${userInput}`)
        .then(async res => {
          if(res.data.status === "success"){

            const data = await processAutoSugestionResponse(res.data.locationSuggestions);

            this.setState({
              activeSuggestion: 0,
              filteredSuggestions: data,
              showSuggestions: true,
            });
          }
        });
  };

  onChangeWithDelay = (input) => {
    this.props.triggerParentLocation(input);

    if( this.state.onChangeTimeout !== null ){
        clearTimeout(this.state.onChangeTimeout)
    }

    const onChangeTimeout = setTimeout(() => {
      this.onChange(input)
      }, 300)

    this.setState({
      ...this.state,
      onChangeTimeout
    })


  }

  onSelect = async () => {

    const url = process.env.REACT_APP_PROVIDER_AUTOSUGEST_URL;

    let res = await Axios.get(`${url}/locations/locationcontent/autosuggest`)

    const data = await processAutoSugestionResponse(res.data.locationSuggestions);

    this.setState({
      activeSuggestion: 0,

      // filteredSuggestions: res.data.locationSuggestions.slice(0, 5),
      filteredSuggestions: data,
      showSuggestions: true,
    });
  }

  onClick = (e, suggestion) => {

    this.setState({
      activeSuggestion: suggestion.id,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: suggestion.fullName
    });

    this.props.triggerParentLocationClick(e, suggestion.fullName, suggestion.id)
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      this.props.triggerParentLocation(filteredSuggestions[activeSuggestion].fullName);
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion].fullName
      });

    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40 ) {
      if (activeSuggestion < filteredSuggestions.length -1) {
        this.setState( prevState => ({ activeSuggestion: prevState.activeSuggestion + 1 }) )
      }

    }
  };

  render() {
    const {
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;
    const { triggerParentLocation } = this.props
    const mobileulstyles={
          marginTop:"5rem",
          marginLeft:"2.5rem",
          zIndex:"1"
    }
    let suggestionsListComponent = (<div></div>);

    if (showSuggestions && filteredSuggestions.length > 0) {
     // if (filteredSuggestions.length) {
      let uniquefilteredSuggestions = filteredSuggestions.filter((v, i, a) => a.indexOf(v) === i);

        suggestionsListComponent = (
          <ul className="suggestions" style={this.props.suggestionsStyle}>
            {uniquefilteredSuggestions.map((suggestion) => {
              let className = '';

              if( suggestion.type === 'Airport' || suggestion.type === 'TrainStation' || suggestion.type === 'MultiCity' ) return <></>;

              // Flag the active suggestion with a class
              if (suggestion.id === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={suggestion.id} onClick={(e) => onClick(e, suggestion)}>
                  {/*{suggestion.type === 'Hotel' && <i className="fas fa-hotel"></i> }*/}
                  {suggestion.type === 'Hotel' && "(Hotel)"}
                  {/*{suggestion.type === 'Airport' && <i className="fas fa-plane"></i> }*/}
                  {suggestion.type === 'Airport' && "(Airport)" }
                  {/*{suggestion.type === 'TrainStation' && <i className="fas fa-subway"></i> }*/}
                  {suggestion.type === 'TrainStation' && "(TrainStation)" }
                  {/*{suggestion.type === 'PointOfInterest' && <i className="fas fa-map-marker-alt"></i> }*/}

                  &nbsp;{suggestion.fullName}
                </li>
              );
            })}
          </ul>
        );
     // }
    }

    return (
      <div className="autocomplete" style={{display:`${this.props.className=="orangeSearchbar"?"inline-flex":"block"}`}}>
        <Input
          type="text"
          placeholder={this.props.placeholder || "Select Destination"}
          name="place"
          // onBlur={(e) => this.setState({...this.state, showSuggestions:false})}
          onChange={(e) => {
            e.preventDefault();
            this.onChangeWithDelay(e.target.value);
          }}
          // onSelect={this.onSelect}
          className={this.props.className}
          onKeyDown={onKeyDown}
          value={this.props.value}
          autoComplete="off"
          style={this.props.inputStyle}
        />
        {suggestionsListComponent}
      </div>
    );
  }
}

export default Autocomplete;
