import React from "react";
import "./LegalDocuments.css";
import Header from "../components/Header/Header";
import FooterPage from "../components/Footer";
import background from "../images/aboutus.jpg";

const AboutUs = () => {
  return (
    <div className="docsPage">
      <Header />
      <div
        className="aboutUsMainContainer docsbackground"
        style={{ backgroundImage: `url( ${background} )` }}
      >
        <div className="aboutUs">
          <span className="docsHeading" style={{marginLeft:0}}>About Us</span>
          <div className="docsSubHeading" style={{ color: "white" }}>
            VisitorsDeals its an idea to give special deals to you when you use
            our site to book your rooms, we have more than 900,000 unique
            properties from all around the world.
          </div>
          <div className="docsSubHeading" style={{ color: "white" }}>
            We aggregate the rates from multiple suppliers and always give you
            the lowest rate possible for the room you have selected. We always
            strive to make your travel experience a better one, so for your up
            coming trip book your rooms with us now.
          </div>
        </div>
      </div>
      <FooterPage />
    </div>
  );
};

export default AboutUs;
