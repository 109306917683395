import React from "react";
import { Skeleton } from "antd";
import "./Skeleton.css";

export const RoomSkeleton = () => {
  return (
    <div className="RoomSkeleton">
      <div className="title">
        <Skeleton
          title={false}
          paragraph={{ rows: 2, width: ["100%", "100%"] }}
          active
        />
      </div>
      <div className="image">
        <Skeleton
          title={false}
          paragraph={{
            rows: 10,
            width: [
              "100%",
              "100%",
              "100%",
              "100%",
              "100%",
              "100%",
              "100%",
              "100%",
              "100%",
              "100%",
            ],
          }}
          active
        />
      </div>
      <Skeleton
        title={false}
        paragraph={{ rows: 4, width: ["25%", "65%", "55%", "30%"] }}
        active
      />
    </div>
  );
};
