import React from "react";

const HomeContent = ({isMobile}) => {

  const terms = [
      'Free Roam Like Home pass to Yoodo users who make accommodation bookings for worldwide destination hotels with VisitorsDeals.',
      'Only accommodation bookings of minimum 14 days before travel date are eligible for this deal.',
      'Free roaming passes based on the length of accommodation booking, i.e. customer will get 5 days free roaming passes for accommodation booking of 5 days on VisitorsDeals platform, in the denominations of 1-day, 3-day or 7-day pass.',
      'To avail the deal, visit: <a href="https://www.VisitorsDeals.com/Yoodo">https://www.VisitorsDeals.com/Yoodo</a> and proceed with the following steps:<br/>' +
      '   a) Sign up or log in to a registered VisitorsDeals account<br/>' +
      '   b) Search for the accommodation and travel dates<br/>' +
      '   c) Choose the preferred accommodation and room type<br/>' +
      '   d) Insert Yoodo mobile number and confirm the booking<br/>' +
      '   e) Receive Roam Like Home passes on Yoodo app within 14 days prior to the travel date',
      'A valid Yoodo mobile number is required to qualify for the deal. If you’re not a Yoodo user, sign up here at <a href="https://www.yoodo.com.my">www.yoodo.com.my</a>',
      'An active Yoodo plan is required to receive the free Roam Like Home passes and customer should have a base plan quota (i.e. data, voice or SMS) to use the passes overseas',
      'The rewarded Roam Like Home passes must be activated within 60 days after receiving them on the Yoodo app. ',
      'The Roam Like Home passes will be activated automatically when customer starts using data, voice or SMS overseas.',
      'Only one pass will be automatically activated at one time.',
      'Further information on Roam Like Home can be found at product FAQ page <a href="https://www.yoodo.com.my/roaming">https://www.yoodo.com.my/roaming</a> ',
      'The offer is subject to terms and conditions of VisitorsDeals. In case of dispute, the decision of VisitorsDeals and Yoodo shall be final.',
      'All conditions of the promotion must be met at the time the booking is made; no retroactive claims will be allowed.',
      'VisitorsDeals reserves the right, in its sole discretion, to disqualify any reward from an eligible user who is found in violation of the terms and conditions or undertaken fraudulent activities harmful to this campaign.',
      'For inquiries and concerns about the offer, you may call VisitorsDeals Customer Service or write to us at <a href="mailto:contactus@visitorsdeals.com">contactus@visitorsdeals.com</a> or call us at +6012-2091106',
      'Roaming confirmation form must be filled with correct Yoodo’s mobile details after room booking is confirmed.'
  ];

  return (
      <div style={{padding:isMobile ? 10 : 50, maxWidth:'1440px', marginRight:'auto', marginLeft:'auto'}}>
        <div style={{
          justifyContent:'center', display:'flex', border:'2px solid #EB234A',
          borderRadius:10,
          marginBottom:20,
        }}>
          <p style={{fontSize:18, fontWeight:700, color:'black', padding:25, marginBottom:0}}>Terms & Conditions</p>
        </div>
        <div style={{marginTop:10, border:'1px solid #E9EDEF', padding:20, borderRadius:10}}>
          <div style={{color:'black'}}>
            {
              terms.map((ter, index) => {
                return (
                    <div style={{fontWeight:500, fontSize:16, marginTop:index === 0 ? 'inherit' : 10, display:'flex', flexDirection:'row', flexWrap : isMobile ? 'wrap' : 'inherit'}}>
                      <div style={{marginRight:10}}>{`${index+1}.`}</div>
                      <p style={{}}>
                        <div dangerouslySetInnerHTML={{ __html: ter}}/>
                      </p>
                    </div>
                )
              })
            }
            {/*<p style={{fontWeight:500, fontSize:16}}>1. Free Roam Like Home pass to Yoodo users who make accommodation bookings for worldwide destination*/}
            {/*  hotels with VisitorsDeals.</p>*/}
            {/*<p style={{fontWeight:500, fontSize:16, marginTop:30  }}>2. Only accommodation bookings of minimum 14 days before travel date are eligible for this deal.</p>*/}
          </div>
        </div>
      </div>
  )
}

export default HomeContent;
