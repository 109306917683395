import { Carousel } from "antd";
import React, {useState} from "react";
import Header from "../../components/Header/Header";
import FooterPage from "../../components/Footer";
import SearchBar from "../../components/SearchBar/SearchBar";


import { PopularDestinations } from "./PopularDestinations";
import { NewsLetter } from "../../components/NewsLetter";

import des1 from "../../images/Home/des1.jpg"
import des2 from "../../images/Home/des2.jpg"
import des3 from "../../images/Home/des3.jpg"
import des4 from "../../images/Home/des4.jpg"
import des5 from "../../images/Home/des5.jpg"
import des6 from "../../images/Home/des6.jpg"
import des7 from "../../images/Home/des7.jpg"
import des8 from "../../images/Home/des8.jpg"
import des9 from "../../images/Home/des9.jpg"
import des10 from "../../images/Home/des10.jpg"
import HomeContent from "../../components/Provider/Yoodo/HomeContent";

export const HomeMobile = ({ hasProvider, provider, history }) => {
  const [openLogin, setOpenLogin] = useState(false);
  const [providerConfig, setProviderConfig] = useState({
    status: false,
    name: ''
  });

  let pConfig = {
    name : null,
    textColor : '',
    searchBar : () => {},
    content : () => {}
  };
  switch (provider){
    case 'yoodo':
      pConfig.name = provider;
      pConfig.textColor= "black";

      localStorage.setItem(process.env.REACT_APP_ENV+'_VD_PROVIDER', JSON.stringify({
        ...JSON.parse(localStorage.getItem(process.env.REACT_APP_ENV+'_VD_PROVIDER')),
        config : pConfig
      }))
      break;
    default:
      break;
  }


  const places = [
    {
      place: "Kuala Lumpur, Malaysia",
      placeId:228226,
      url: des1,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "Singapore",
      placeId:246673,
      url: des2,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "Sydney, Australia",
      placeId:383923,
      url: des3,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "London, United Kingdom",
      placeId: 435089,
      url: des4,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "New York, United States",
      placeId:4349,
      url: des5,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "Qatar",
      placeId:213428,
      url: des6,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "Paris, France",
      placeId:665191,
      url: des7,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "Taipei, Taiwan",
      placeId:246729,
      url: des8,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "Athens, Greece",
      placeId:480712,
      url: des9,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
    {
      place: "Istanbul, Turkey",
      placeId:213435,
      url: des10,
      cost: "99.9",
      imageClass: "mobileImage",
      history,
    },
  ];

  const DefaultHomeBar = () => {
    return (
        <>
          {/*<div className="homeHeading">*/}
          {/*  If you follow your heart it will lead you to the airport*/}
          {/*</div>*/}
          <div className="homeHeading" style={{width:'80vw'}}>
            {/*Stay Connected  <a href='/esim'><img alt={'esim'} style={{marginBottom:'5px', width:'50px'}} src={'esim-b.png'}/></a><br/>*/}
            {/*<span> Free mobile roaming in 121 countries</span>*/}
            Book your room with best rates + FREE data for roaming .
          </div>
          <SearchBar showLogin={() => setOpenLogin(new Date())} history={history} />
          <div className="homeHeading" style={{ width: "100%" }}>
            {/*Book a room and get 1GB FREE data for roaming. DAILY.*/}
            Book your room in these cities and get FREE 1GB of data for roaming. DAILY.
          </div>
        </>
    )
  }

  const YoodoHomeBar = () => {
    return (
        <>
          <div className="homeHeading" style={{width:'100%', textAlign:"center", marginLeft:'auto', marginRight:'auto'}}>
            Travel and enjoy FREE roaming passes in 72 countries with Yoodo!
          </div>
          <SearchBar showLogin={() => setOpenLogin(new Date())} history={history} />
          <div className="homeHeading" style={{ width: "100%" }}>
            We have over 900,000 properties worldwide
          </div>
        </>
    )
  }

  const DefaultHomeContent = () => {
    return (
        <Carousel autoplay={true} dotsClass="carouselContainerDots" dots={true}>
          {places.map((place, index) => (
              <div key={index} className="carouselContainer">
                <PopularDestinations key={index} {...place} showLogin={() => setOpenLogin(!openLogin)} />
              </div>
          ))}
        </Carousel>
    )
  }

  return (
    <div className="HomeMobile">
      <Header
          openLoginModal={openLogin}
      />
      {hasProvider ? <YoodoHomeBar/> : <DefaultHomeBar/>}
      {hasProvider ? <HomeContent isMobile/> : <DefaultHomeContent/>}
      <NewsLetter />
      <FooterPage hasProvider={hasProvider} />
    </div>
  );
};
