import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  Form,
  Input,
  Button,
  InputNumber,
  Checkbox,
  Icon,
  Spin,
  Modal,
} from "antd";
import "./PersonalDetails.css";
import Header from "../../components/Header/Header";
import FooterPage from "../../components/Footer";
import { CountrySelector } from "./CountrySelector";
import { RegionSelector } from "./RegionSelector";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import MediaQuery, { useMediaQuery } from "react-responsive";
import Axios from "axios";
import {_defaultHeaders, getCurrency} from "../../AxiosHelper";
import { ROOMS } from "../../RouteNames";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import {_gtag, formatPolicyText, formatPolicyType, getIsTestAcc, processCancellationPolicy} from "../../Helper";
import {Elements, useStripe, useElements, CardElement} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize : '20px',
      color: "#424770",
      letterSpacing: "0.025em",
      "::placeholder": {
        color: "#aab7c4"
      }
    },
    invalid: {
      color: "#9e2146"
    }
  }
};

const CheckoutPageForm = (props) => {

  const stripe = useStripe();
  const elements = useElements();


  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 775px)",
  });

  let creditCardRequired = true;
  const rateId = [];
  const roomId = [];
  const roomAllocation = [];
  const corelationId = props.location.state.recommendationData.correlationId;
  const token = props.location.state.recommendationData.token;
  const hotelId = props.location.state.recommendationData.hotel.id;
  const rates = props.location.state.recommendationData.hotel.rates;
  const rooms = props.location.state.recommendationData.hotel.rooms;
  const guestDetail = props.location.state.guestDetails;

  rates.forEach((rt) => {
    if (rt.cardRequired) {
      creditCardRequired = true;
    }
  });

  const [sameAddress, setsameAddress] = useState(true);
  const [termsAndConditions, settermsAndConditions] = useState(false);
  const [viewMore, setviewMore] = useState(isDesktopOrLaptop ? true : false);
  const [isLoading, setIsLoading] = useState(false);
  const [cardErrorMsg, setCardErrorMsg] = useState(null);
  const [cardValidation, setCardValidation] = useState(false);

  const {
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
    meta,
  } = usePaymentInputs();
  const [policyModalVisible, setpolicyModalVisible] = useState(false);
  const { getFieldDecorator } = props.form;

  const BookApi = async (values) => {

    const roomOccupantDetail = guestDetail.panes[0]

    const leadGuestInfo = {
      type: "Adult",
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: "",
      suffix: "",
      age: values.age,
      email: values.email,
    }

    rates.forEach((rt, index) => {
      // TODO :: Adult
      const guest = [leadGuestInfo]
      if( roomOccupantDetail[index].children > 0 ){
        guest.push({
          type: "child",
          age : roomOccupantDetail[index].childrenInfo[0]
        })
      }

      roomAllocation.push({
        roomId: rt.occupancies[0].roomId,
        rateId: rt.id,
        guests: guest
      });

      rateId.push(rt.id);
    });

    const monthYear = values.expiryDate?.split("/");

    const address = {
      line1: values.address,
      line2: "",
      city: {
        name: values.city,
        code: "",
      },
      state: {
        name: values.state,
        code: "",
      },
      country: {
        name: values.country,
        code: values.country,
      },
      postalCode: values.code,
    };

    let billAddress = address;
    if (values.billingaddress) {
      billAddress = {
        line1: values.billingaddress,
        line2: "",
        city: {
          name: values.billingcity,
          code: "",
        },
        state: {
          name: values.billingstate,
          code: "",
        },
        country: {
          name: values.billingcountry,
          code: values.billingcountry,
        },
        postalCode: values.billingcode,
      };
    }

    let creditCard = {
      number: values.cardNumber?.replaceAll(" ", ""),
      nameOnCard: values.cardName,
      cvv: values.cvv,
      issuer: "VI",
      expiryMonth: monthYear ? monthYear[0]?.trim() : "",
      expiryYear: monthYear ? `20${monthYear[1]?.trim()}` : "", //TODO :: TIMEBOMB

      contact: {
        billingaddress: billAddress,
        phone: values.phone?.toString(),
        email: values.email,
      },
    };

    let data = {
      rateIds: rateId,
      roomsAllocations: roomAllocation,
      billingContact: {
        title: values.title,
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: "",
        age: values.age,
        contact: {
          address: address,
          phone: values.phone.toString(),
          email: values.email,
        },
      },
    };

    if (creditCardRequired) {
      data = { ...data, creditCard: creditCard };
    }

    setIsLoading(true);
    let currency = getCurrency();
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_PROVIDER_URL}/hotel/${hotelId}/${token}/book`,
      headers: _defaultHeaders(currency, corelationId),
      data: data,
    };

    // const res = await Axios(config)
    //     .then((res) => {
    //       if (res.data.bookingStatus === "Confirmed") {
    //         alert("Booking Success");
    //         // TODO :: Need navigate to booking complete page and send user booking confirm receipt
    //         props.history.push({
    //           pathname: "/",
    //         });
    //       } else {
    //         // TODO :: Need to show booking failed page
    //         alert(`Booking Failed. Error-TraceID : ${corelationId}`);
    //       }
    //     })
    //     .catch((err) => {
    //       alert(
    //           `Booking Failed. ${err.toString()}. Error-TraceID : ${corelationId}`
    //       );
    //     })
    //     .finally(() => setIsLoading(false));
  };

  const PaymentInit = async (values) => {

    _gtag('begin_checkout');

    const roomOccupantDetail = guestDetail.panes[0]

    const leadGuestInfo = {
      type: "Adult",
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: "",
      suffix: "",
      age: values.age,
      email: values.email,
    }

    rates.forEach((rt, index) => {

      const guest = [leadGuestInfo]
      if( roomOccupantDetail[index].children > 0 ){
        guest.push({
          type: "child",
          age : roomOccupantDetail[index].childrenInfo[0]
        })
      }

      roomAllocation.push({
        roomId: rt.occupancies[0].roomId,
        rateId: rt.id,
        guests: guest,
      });

      rateId.push(rt.id);
    });

    let currency = getCurrency();
    let useremail = localStorage.getItem("useremail");
    let config = {
      method: "post",
      url: 'payment/checkout',
      headers: _defaultHeaders(currency, corelationId),
      data: {
        stripe_id : null,
        useremail,
        currency,
        hotelInfo : {
          checkinDate :  props.location.state.checkinDate,
          checkoutDate :  props.location.state.checkoutDate,
          roomInfo : {
            count : props.location.state.Roomcount,
            guestDetails : props.location.state.guestDetails
          },
          price : props.location.state.rateDetails,
          rates : props.location.state.recommendationData?.hotel?.rates,
          address : props.location.state.hotelAddress,
          image : props.location.state.hotelImage,
          hotelname: props.location.state.hotelname,
          token,
          id : props.location.state.recommendationData?.hotel?.id,
          bookingInfo : {
            billingContact : values,
            rateIds: rateId,
            roomsAllocations: roomAllocation,
          }
        },
      },
    };

    // const fakeBooking = () => {
    //   const res = {ref_id : 6}
    //   window.location.href = `/payment/booking?refId=${res.ref_id}&type=INPROGRESS`
    // }
    //
    // setTimeout(() => {
    //   fakeBooking()
    // }, 5000);


    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      // card: {
      //   number: '4242424242424242',
      //   exp_month: 4,
      //   exp_year: 2023,
      //   cvc: '314',
      // },
      billing_details: {
        address : {
          country : values.billingcountry,
          postal_code : values.billingcode,
          line1 : values.billingaddress,
          state : values.billingstate
        },
        email : values.email,
        phone : values.phone,
        name: values.firstName+" "+values.lastName,
      },
    });

    if (result.error) {
      // Show error in payment form
      setCardErrorMsg(result.error.message)
    }
    else {
      config.data.stripe_id = result.paymentMethod.id;

      const isTestAcc = getIsTestAcc();

      config.data.isTest = isTestAcc ?? false;

      await Axios(config)
          .then((respose) => {

            const res = respose.data
            if( res.status && !res.requires_action ){
              window.location.href = `/payment/booking?refId=${res.ref_id}&type=INPROGRESS`


            } else if( res.status && res.requires_action ){
              // stripe action
              window.location.href = `/payment/checkout?secret=${res.payment_intent_client_secret}&refId=${res.ref_id}`
            } else{
              setCardErrorMsg(res.error)
              // alert(
              //     `Booking Failed. Payment Intent Failed (${res.error}). Booking ID : ${res.ref_id}. Error-TraceID : ${corelationId}`
              // );
            }


            // props.history.push({
            //   pathname: "/payment/checkout",
            // });

            // if( res.data.status ){
            //   window.location.href = res.data.url;
            //   // props.history.push(res.data.url);
            // }

          })
          .catch((err) => {
            alert(
                `Booking Failed. ${err.toString()}. Error-TraceID : ${corelationId}`
            );
          })
          .finally(() => setIsLoading(false));

    }
  }


  const handleSubmit = () => {
    props.form.validateFields((err, values) => {
      let cardErrors = false;
      if (meta.erroredInputs.cardNumber) {
        cardErrors = true;
        props.form.setFields({
          cardNumber: {
            value: values.cardNumber,
            errors: [new Error(meta.erroredInputs.cardNumber)],
          },
        });
      }
      if (meta.erroredInputs.expiryDate) {
        cardErrors = true;
        props.form.setFields({
          expiryDate: {
            value: values.expiryDate,
            errors: [new Error(meta.erroredInputs.expiryDate)],
          },
        });
      }
      if (meta.erroredInputs.cvc) {
        cardErrors = true;
        props.form.setFields({
          cvv: {
            value: values.cvv,
            errors: [new Error(meta.erroredInputs.cvc)],
          },
        });
      }

      if (!err && !cardErrors) {
        if (sameAddress) {
          values.billingcountry = values.country;
          values.billingstate = values.state;
          values.billingcode = values.code;
          values.billingaddress = values.address;
        }

        setIsLoading(true);
        // BookApi(values)
        PaymentInit(values);
      }
    });
  };

  const Loading = () => (
      <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
      >
        <Spin size="large" />
      </div>
  );

  useEffect(() => {
    _gtag('add_to_cart');

    window.scrollTo(0, 0);
  }, []);

  const policyModal = () => {
    const rates = props.location.state.recommendationData?.hotel?.rates[0];
    let refundTooltip = '';
    if (rates?.cancellationPolicies?.length) {
      const res = processCancellationPolicy(rates.cancellationPolicies);
      refundTooltip = `<div>${res.shortText}</div>`;
      refundTooltip += res.table;
    }
    return (
        <Modal
            title="Policies"
            visible={policyModalVisible}
            onCancel={() => setpolicyModalVisible(false)}
            closable={false}
            footer={[
              <Button key="back" onClick={() => setpolicyModalVisible(false)}>
                Close
              </Button>,
            ]}
        >
          {refundTooltip.length > 0 && (
              <div style={{marginBottom:'10px'}}>
                <strong>Refundable Policy:</strong>
                <span dangerouslySetInnerHTML={{__html:refundTooltip}}/>
              </div>
          )}

          {props.location.state.recommendationData?.hotel?.rates[0]?.policies?.map(
              (policy, index) => (
                  <div key={index}>
                    <strong>{ formatPolicyType(policy.type) }:</strong>
                    <div style={{whiteSpace:'break-spaces'}}>{formatPolicyText(policy.text)}</div>
                  </div>
              )
          )}
        </Modal>
    )};

  // if (isLoading) return <Loading />;

  return (
      <div className="PersonalDetailsContainer">
        {(
            props.location.state.recommendationData?.hotel?.rates[0]?.policies?.length > 0 ||
            props.location.state.recommendationData?.hotel?.rates[0]?.cancellationPolicies?.length > 0)
        && policyModal() }
        <Header />

        <div className="PersonalDetails">
          <div className="bookingHotelInfo">
            <div className="outerBoxSpacer" />

            <div className="outerBox">
              <div className="topheading">
                Booking Summary
                <MediaQuery maxWidth={775}>
                  <Icon
                      type={viewMore ? "minus" : "plus"}
                      onClick={() => setviewMore(!viewMore)}
                  />
                </MediaQuery>
              </div>

              {viewMore && (
                  <div>
                    <div
                        style={{
                          backgroundImage: `url(${props.location.state.hotelImage})`,
                        }}
                        className="imageDiv"
                    />
                    <div
                        style={{
                          color: "black",
                          fontSize: "1.5rem",
                          marginBottom: "1rem",
                        }}
                    >
                      {props.location.state.hotelname}
                    </div>
                    <div
                        style={{
                          color: "black",
                          fontSize: "1.25rem",
                          marginBottom: "1rem",
                        }}
                    >
                      {props.location.state.hotelAddress}
                    </div>
                    <div className="infoBox">
                      <div className="subHeading">Check In</div>
                      <div
                          style={{
                            color: "black",
                            paddingBottom: "1rem",
                            marginBottom: "1rem",
                            borderBottom: "1px solid #E9EDEF",
                          }}
                      >
                        {moment(props.location.state.checkinDate).format("dddd")},{" "}
                        {props.location.state.checkinDate} at{" "}
                        {props.location.state.checkintime}
                      </div>
                      <div className="subHeading">Check out</div>
                      <div style={{ color: "black", marginBottom: "1rem" }}>
                        {moment(props.location.state.checkoutDate).format("dddd")},{" "}
                        {props.location.state.checkoutDate} at{" "}
                        {props.location.state.checkouttime}
                      </div>

                      <div className="flexSpWrap">
                        <div className="flexcol bookingModalInfo">
                          <div className="subHeading">Rooms</div>
                          <div>{props.location.state.Roomcount}</div>
                        </div>

                        <div className="flexcol bookingModalInfo">
                          <div className="subHeading">Nights</div>
                          <div>{props.location.state.nights}</div>
                        </div>

                        <div className="flexcol bookingModalInfo">
                          <div className="subHeading">Adults</div>
                          <div>{props.location.state.guestDetails.noofAdults}</div>
                        </div>

                        {props.location.state.guestDetails.noofChild > 0 && (
                            <div className="flexcol bookingModalInfo">
                              <div className="subHeading">Children</div>
                              <div>{props.location.state.guestDetails.noofChild}</div>
                            </div>
                        )}
                        {props.location.state.guestDetails.noofChild > 0 && (
                            <div className="flexcol bookingModalInfo">
                              <div className="subHeading">Age</div>
                              <div>{props.location.state.guestDetails.ages}</div>
                            </div>
                        )}
                      </div>
                    </div>

                    <div
                        style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div className="subHeading">Total (Including all taxes)</div>
                      <div style={{ color: "#F48247", marginBottom: "1rem" }}>
                        {localStorage.getItem("currency")}{" "}
                        {props.location.state.rateDetails
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </div>
                    </div>
                  </div>
              )}
            </div>
          </div>
          <div className="bookingDetailsContainer">
            <div className="mainHeading">Personal Details</div>
            <div className="bookingDetails">
              {/*<div className="inputContainer title">*/}
              {/*  <Form.Item style={{ margin: 0 }}>*/}
              {/*    {getFieldDecorator("title", {*/}
              {/*      rules: [*/}
              {/*        {*/}
              {/*          required: true,*/}
              {/*          message: "Please select a title",*/}
              {/*        },*/}
              {/*      ],*/}
              {/*    })(*/}
              {/*      <Select*/}
              {/*        style={{ width: 100, border: "none" }}*/}
              {/*        placeholder="Title"*/}
              {/*      >*/}
              {/*        <Select.Option value="Mr">Mr.</Select.Option>*/}
              {/*        <Select.Option value="Ms">Ms.</Select.Option>*/}
              {/*        <Select.Option value="Mrs">Mrs.</Select.Option>*/}
              {/*      </Select>*/}
              {/*    )}*/}
              {/*  </Form.Item>*/}
              {/*</div>*/}

              <div className="inputContainer firstname">
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("firstName", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your name",
                      },
                      {
                        type: "string",
                        message: "Please input a valid name",
                      },
                    ],
                    initialValue: `${
                        JSON.parse(localStorage.getItem("userdata")).first_name
                    }`,
                  })(
                      <Input
                          style={{ border: "none", width: "100%" }}
                          placeholder="First Name"
                      />
                  )}
                </Form.Item>
              </div>
              <div className="inputContainer lastname">
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("lastName", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your name",
                      },
                      {
                        type: "string",
                        message: "Please input a valid name",
                      },
                    ],
                    initialValue: `${
                        JSON.parse(localStorage.getItem("userdata")).last_name
                    }`,
                  })(
                      <Input
                          style={{ border: "none", width: "100%" }}
                          placeholder="Name"
                      />
                  )}
                </Form.Item>
              </div>

              <div
                  className="inputContainer"
                  style={{ display: "flex", alignItems: "center" }}
              >
                <Form.Item style={{ margin: 0, width: "100%" }}>
                  {getFieldDecorator("phone", {
                    rules: [
                      {
                        required: true,
                        message: "Invalid Phone Number",
                      },
                    ],
                    initialValue: JSON.parse(localStorage.getItem("userdata"))
                        .contactNumber,
                  })(
                      <PhoneInput
                          inputClass="profiletelephoneinput"
                          containerClass="profiletelephoneinput"
                          dropdownStyle={{ border: "none" }}
                          placeholder="Phone"
                      />
                  )}
                </Form.Item>
              </div>

              <div className="inputContainer">
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter your email",
                      },
                      {
                        type: "email",
                        message: "Please input a valid email",
                      },
                    ],
                    initialValue: `${localStorage.getItem("useremail")}`,
                  })(
                      <Input
                          style={{ border: "none", width: "100%" }}
                          placeholder="Email"
                      />
                  )}
                </Form.Item>
              </div>

              <div className="inputContainer">
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("age", {
                    rules: [
                      { type: "integer", required: true, message: "Invalid Age" },
                    ],
                  })(
                      <InputNumber
                          style={{ border: "none", width: "100%" }}
                          placeholder="Age"
                      />
                  )}
                </Form.Item>
              </div>
            </div>

            {/* Address */}
            <div className="bookingDetails" style={{ marginBottom: 0 }}>
              <div className="inputContainer">
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("address", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your address",
                      },
                    ],
                  })(
                      <Input
                          style={{ border: "none", width: "100%" }}
                          placeholder="Address"
                      />
                  )}
                </Form.Item>
              </div>

              <div className="doubleInput">
                <div
                    className="inputContainer"
                    style={{ width: "auto", marginRight: "1rem" }}
                >
                  <Form.Item style={{ margin: 0 }}>
                    {getFieldDecorator("code", {
                      rules: [
                        {
                          required: true,
                          message: "Invalid postal code",
                        },
                      ],
                    })(
                        <Input
                            style={{ border: "none", width: "100%" }}
                            placeholder="Postal Code"
                        />
                    )}
                  </Form.Item>
                </div>

                <div className="inputContainer" style={{ width: "auto" }}>
                  <Form.Item style={{ margin: 0 }}>
                    {getFieldDecorator("city", {
                      rules: [
                        {
                          type: "string",
                          required: true,
                          message: "Please enter your city",
                        },
                      ],
                    })(
                        <Input
                            style={{ border: "none", width: "100%" }}
                            placeholder="City"
                        />
                    )}
                  </Form.Item>
                </div>
              </div>

              <div className="inputContainer">
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("country", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter your country",
                      },
                    ],
                  })(
                      <CountrySelector
                          style={{ border: "none", width: "100%" }}
                          onChange={(value) => {
                            props.form.setFieldsValue({
                              country: value,
                              state: undefined,
                            });
                          }}
                      />
                  )}
                </Form.Item>
              </div>

              <div className="inputContainer">
                <Form.Item style={{ margin: 0 }}>
                  {getFieldDecorator("state", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter your state",
                      },
                    ],
                  })(
                      <RegionSelector
                          style={{ border: "none", width: "100%" }}
                          disabled={!!!props.form.getFieldValue("country")}
                          country={props.form.getFieldValue("country")}
                      />
                  )}
                </Form.Item>
              </div>
            </div>
            {/* closing personal details */}

            {creditCardRequired && (
                <div>
                  <div className="mainHeading" style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                    Credit Card Details
                  </div>
                  <div style={{marginBottom:'2rem'}}>
                    <span style={{color:'red'}}>{cardErrorMsg}</span>
                    <CardElement onChange={(e) => e.complete && !e.error ? setCardValidation(true) : setCardValidation(false) }/>
                    {/*<CardNumberElement/>*/}
                    {/*<CardExpiryElement/>*/}
                    {/*<CardCvcElement/>*/}
                  </div>
                </div>
            )}
            <div>
              <Checkbox
                  checked={sameAddress}
                  onChange={() => setsameAddress(!sameAddress)}
                  style={{ marginBottom: "4rem" }}
              >
                Billing address is the same as shipping address
              </Checkbox>
            </div>
            {/* Billing address */}
            {!sameAddress && (
                <div className="bookingDetails">
                  <div className="inputContainer">
                    <Form.Item style={{ margin: 0 }}>
                      {getFieldDecorator("billingaddress", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your billing address",
                          },
                        ],
                      })(
                          <Input
                              style={{ border: "none", width: "100%" }}
                              placeholder="Billing address"
                          />
                      )}
                    </Form.Item>
                  </div>

                  <div className="doubleInput">
                    <div
                        className="inputContainer"
                        style={{ width: "auto", marginRight: "1rem" }}
                    >
                      <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator("billingcode", {
                          rules: [
                            {
                              required: true,
                              message: "Invalid postal code",
                            },
                          ],
                        })(
                            <Input
                                style={{ border: "none", width: "100%" }}
                                placeholder="Postal Code"
                            />
                        )}
                      </Form.Item>
                    </div>

                    <div className="inputContainer" style={{ width: "auto" }}>
                      <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator("billingcity", {
                          rules: [
                            {
                              type: "string",
                              required: true,
                              message: "Please enter the billing city",
                            },
                          ],
                        })(
                            <Input
                                style={{ border: "none", width: "100%" }}
                                placeholder="Billing City"
                            />
                        )}
                      </Form.Item>
                    </div>
                  </div>

                  <div className="inputContainer">
                    <Form.Item style={{ margin: 0 }}>
                      {getFieldDecorator("billingcountry", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter the billing country",
                          },
                        ],
                      })(
                          <CountrySelector
                              placeholder="Select the billing country"
                              style={{ border: "none", width: "100%" }}
                              onChange={(value) => {
                                props.form.setFieldsValue({
                                  billingcountry: value,
                                  billingstate: undefined,
                                });
                              }}
                          />
                      )}
                    </Form.Item>
                  </div>

                  <div className="inputContainer">
                    <Form.Item style={{ margin: 0 }}>
                      {getFieldDecorator("billingstate", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter your billing state",
                          },
                        ],
                      })(
                          <RegionSelector
                              placeholder="Select the billing state"
                              style={{ border: "none", width: "100%" }}
                              disabled={!!!props.form.getFieldValue("billingcountry")}
                              country={props.form.getFieldValue("billingcountry")}
                          />
                      )}
                    </Form.Item>
                  </div>
                </div>
            )}
            <div>
              <Checkbox
                  checked={termsAndConditions}
                  onChange={() => settermsAndConditions(!termsAndConditions)}
                  style={{ marginBottom: "4rem" }}
              >
                Agree to terms and conditions
              </Checkbox>
              {(props.location.state.recommendationData?.hotel?.rates[0]?.policies?.length > 0 ||
                  props.location.state.recommendationData?.hotel?.rates[0]?.cancellationPolicies?.length > 0) && (
                  <Button
                      type="link"
                      style={{ color: "var(--vdOrange", padding: 0 }}
                      onClick={() => setpolicyModalVisible(true)}
                  >
                    View Policy
                  </Button>
              )}
            </div>
            { isLoading &&
              <Button
                  type="primary"
                  htmlType="submit"
                  style={{marginRight: "1rem"}}
                  disabled={true}
              >
                Loading...
              </Button>
            }
            {!isLoading &&
              <Button
                  type="primary"
                  htmlType="submit"
                  style={{marginRight: "1rem"}}
                  onClick={handleSubmit}
                  disabled={!termsAndConditions || !cardValidation}
              >
                Book
              </Button>
            }

          </div>
        </div>

        <FooterPage />
      </div>
  );
};

const CheckoutPage = Form.create({ name: "PersonalDetails" })(CheckoutPageForm);


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const isTestAcc = getIsTestAcc();
const stripePromise = isTestAcc ? loadStripe(`${process.env.REACT_APP_STRIPE_KEY_TEST}`) : loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const StripeProvider = (props) => {

  return (
      <Elements stripe={stripePromise}>
        <CheckoutPage {...props}/>
      </Elements>
  )
}

export default StripeProvider;
