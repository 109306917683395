import { Button, Icon } from "antd";
import React, { useState } from "react";
import "./HotelDesktop/RoomsAndRates.css";

const Facilities = (props) => {

  const hideMoreButton = props.facilities.length <= props.number ?? false

  const [moreFacilities, setFacilities] = useState(false);

  const handleClick = () => setFacilities(!moreFacilities);

  let list = moreFacilities ? props.facilities : props.facilities.slice(0, props.number);
  return (
    <>
    <div className="facilitiesContainer">
      {list.map((element, index) => (
        <div className="Facilities" key={index}>
          {" "}
          <div>{element.name}</div>{" "}
          <Icon style={{ color: "#F48247" }} type="check" />
        </div>
      ))}


    </div>
      {!hideMoreButton &&
      <div style={{padding: "1rem 0"}}>
        <Button className="facilitiesContainerButton" type="link" onClick={handleClick} style={{color: "#F48247"}}>
          {moreFacilities ? "View less" : "View More"}
        </Button>
      </div>
      }
    </>
  );
};

export default Facilities;
