import { Select } from "antd";
import CountryRegionData from "country-region-data/data.json";
import React, { Component } from "react";

const { Option } = Select;

export class CountrySelector extends Component {
  render() {
    return (
      <Select
        showSearch
        placeholder={this.props.placeholder || "Select a country"}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={this.props.value}
        onChange={this.props.onChange}
        style={this.props.style}
        ref={this.props.ref}
      >
        {CountryRegionData.map((countryInfo) => (
          <Option value={countryInfo.countryShortCode} key={countryInfo.countryName}>
            {countryInfo.countryName}
          </Option>
        ))}
      </Select>
    );
  }
}
