import React, {useState, useEffect} from "react";
import { Route, Switch } from "react-router-dom";

import Error from "./pages/Error";
import Profile from "./pages/Profile";

import AboutUs from "./pages/AboutUs";
import TOS from "./pages/TOS";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUs from "./pages/ContactUs";

import Myaccount from "./pages/Myaccount";
import Transaction from "./components/Transaction";
import "./App.less";
import { Home } from "./pages/Home/Home";
import SearchResults from "./pages/SearchResults/SearchResults";
import AccommodationDetails from "./pages/AccommodationDetails/AccommodationDetails";
import {BOOKING_INFO, HOTEL_DETAIL, PROFILE, ROOMS, ROOMS_LOCATION} from "./RouteNames";
import CheckoutPage from "./pages/PersonalDetails/PersonalDetails";
import { AllBookings } from "./pages/Booking/AllBookings";
import { SingleBooking } from "./pages/Booking/SingleBooking";
import BookingInfo from "./pages/Payment/BookingInfo";
import BookingOngoing from "./pages/Payment/BookingOngoing";
import Esim from "./pages/Esim";
import EsimTos from "./pages/EsimTOS";
import MessengerCustomerChat from "react-messenger-customer-chat";
import {FaWhatsapp} from "react-icons/fa";
import PaymentCheckout from "./pages/PaymentCheckout/PaymentCheckout";
import usePageTracking from "./components/usePageTracking";
import ResetPassword from "./pages/ResetPassword";
import FAQGeneral from "./pages/FAQGeneral";

function App() {

  usePageTracking();

  const [showButton, setShowButton] = useState(false);


  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 5000)
  }, []);

  return (
    <>
      <Switch>
        <Route exact path="/yoodo" component={Home}  />
        {/*<Route exact path={ROOMS} component={SearchResults} />*/}
        <Route exact path={ROOMS_LOCATION} component={SearchResults} />
        <Route exact path={PROFILE} component={Profile} />
        <Route exact path="/" component={Home} />
        <Route exact path={HOTEL_DETAIL} component={AccommodationDetails} />
        <Route exact path="/AboutUs" component={AboutUs} />
        <Route exact path="/ContactUs" component={ContactUs} />
        <Route exact path="/termsandconditions" component={TOS} />
        <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
        <Route exact path={BOOKING_INFO} component={CheckoutPage} />
        <Route exact path="/transactions" component={Transaction} />
        <Route exact path="/Myaccount" component={Myaccount} />
        <Route exact path="/allbooking" component={AllBookings} />

        <Route exact path="/payment/checkout" component={PaymentCheckout} />
        <Route exact path="/payment/booking" component={BookingOngoing} />
        <Route exact path="/payment/success" component={BookingInfo} />
        <Route exact path="/payment/failed" component={BookingInfo} />
        <Route exact path="/booking" component={BookingInfo} />
        {/*<Route exact path="/payment/cancel" component={AllBookings} />*/}

        <Route exact path="/esim" component={Esim} />
        <Route exact path="/esim-tnc" component={EsimTos} />
        <Route exact path="/faq" component={FAQGeneral} />
        <Route exact path="/reset-password" component={ResetPassword} />


        <Route exact path="/single" component={SingleBooking} />
        <Route component={Error} />
      </Switch>
      {showButton && <div style={{position: 'fixed', bottom:'85px', right:'25px', top:'auto', fontSize:'60px'}}>
        <a style={{color: 'green'}} target="_blank"  href="https://wa.link/wixktq">
          <FaWhatsapp/>
        </a>
      </div>}
      { process.env.REACT_APP_ENV !== 'local' &&
        <MessengerCustomerChat
            pageId="1740169049605102"
            appId="208307229975285"
        />
      }
    </>
  );
}

export default App;
