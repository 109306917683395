import React, { Component } from "react";
import { Icon } from "antd";
import RoomPrice from "./RoomPrice";
import Facilities from "../Facilities";
import MediaQuery from "react-responsive";
import ImageGallery from "react-image-gallery";
import Modal from "react-modal";
import "./RoomPriceContainer.css";

export default class RoomPriceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadMore: true,
      modal: false,
      galleryStart: 0,
      roomsDisplayed: this.props.roomList.recomendation.length,
    };

    if (this.props.roomList.recomendation.length === 0)
      this.props.itemFailedToLoad();
  }

  roomFailedToLoad = () => {
    this.setState((state) => ({
      roomsDisplayed: state.roomsDisplayed - 1,
    }));

    if (this.state.roomsDisplayed <= 0) this.props.itemFailedToLoad();
  };

  loadMore = () => {
    this.setState({
      loadMore: !this.state.loadMore,
    });
  };

  open = (index) => {
    document.body.style.overflow = "hidden";
    this.setState({
      galleryStart: index,
      modal: true,
    });
  };

  close = () => {
    document.body.style.overflow = "auto";
    this.setState({
      modal: false,
    });
  };

  loadAllRecomend = () => {
    // TODO :: NEED TO LOAD ALL RECOMENDED ID TO GROUP
  }

  render() {
    const roomData = this.props.roomList.roomDetails;
    const roomRecomandation = this.props.roomList.recomendation;
    const rates = this.props.roomList.rates;
    const room = this.props.roomList.room;
    const imagesArray = roomData.images?.map((image) => {
      return {
        original:
          image.links[1] && image.links[1].url
            ? image.links[1].url
            : image.links[0].url,
        thumbnail:
          image.links[1] && image.links[1].url
            ? image.links[1].url
            : image.links[0].url,
        description: image.caption,
      };
    }) ?? [];

    if (this.state.roomsDisplayed <= 0)
      return <div style={{ display: "none" }} />;

    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.modal}
          className="smallImagesModal"
          // style={customStyles}
        >
          <div className="smallImagesClose">
            <Icon
              type="close-circle"
              style={{ fontSize: "3rem", marginBottom: "1rem" }}
              onClick={this.close}
            />
          </div>
          <ImageGallery
            items={imagesArray}
            showFullscreenButton={false}
            showPlayButton={false}
            startIndex={this.state.galleryStart}
          />
        </Modal>

        <div
          className="combinationCostscontainer shadow"
          style={{
            border: "1px solid #e9edef",
            borderRadius: "10px",
          }}
        >
          <div className="combinationImageName">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="combinationCostscontainerroomName">
                {roomData.name}
              </div>

              {imagesArray.length > 0 && (
                <div className="RoomsImageContainer">
                  {imagesArray.map((element, index) => (
                    <div
                      key={index}
                      className="RoomsImage"
                      style={{
                        backgroundImage: `url( ${element.thumbnail} )`,
                        cursor: "pointer",
                      }}
                      onClick={() => this.open(index)}
                    />
                  ))}
                </div>
              )}

              <MediaQuery maxWidth={775}>
                {roomData.hasOwnProperty("facilities") &&
                  roomData.facilities.length > 0 && (
                    <Facilities number={4} facilities={roomData.facilities} />
                  )}
              </MediaQuery>
              <MediaQuery minWidth={776}>
                {roomData.hasOwnProperty("facilities") &&
                  roomData.facilities.length > 0 && (
                    <Facilities number={12} facilities={roomData.facilities} />
                  )}
              </MediaQuery>

              <div className="roomsPrices">
                {roomRecomandation.length > 0 &&
                  roomRecomandation.map((element,index) => (
                    <RoomPrice
                      hideElem={this.hide}
                      intialData={{
                        rates : rates[index],
                        room : room[index],
                      }}
                      location={this.props.location}
                      currency={this.props.roomList.currency}
                      hotelId={this.props.hotelId}
                      token={this.props.token}
                      config={this.props.config}
                      recommendationId={element?.id}
                      handleSelection={this.props.handleSelection}
                      selectedtoken={this.props.selectedtoken}
                      setPaymentData={this.props.setPaymentData}
                      roomFailedToLoad={this.roomFailedToLoad}
                      key={element?.id}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
