import React from "react";
import "./LegalDocuments.css";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";

const EsimTos = () => {
  return (
    <div className="docsPage" style={{ height: "auto" }}>
      <Header />
      <div className="docsContainer">
        <div className="docsHeading">E-Sim Terms & Conditions</div>
        <p className="docsPara">
          <div className="docsSubHeading">1. 1 eSIM / Per Room</div>
          We will provide 1 eSIM per room booking .
          <br/>
          1 room booking for 1 adult : 1 eSIM
          <br/>
          1 room booking for 2 adults : 1 eSIM
          <br/>
          2 room bookings for 2 adults : 2 eSIMs
          <br/>
          ( If someone made 2 room bookings for 2 adults in a single booking , we will send 2 eSIMs together with the booking confirmation )
          <div className="docsSubHeading">2. Service availability & quality</div>
          We are not responsible if the service is not constantly available or the roaming partner’s data speed is not as promised , all the roaming partners  we work with worldwide provide 3G - 4G connectivity .
          <div className="docsSubHeading">3. eSIM expiration</div>
          The eSIM can be activated anytime , it will be terminated according to your check – out date , let's say if you are checking out today , the eSIM will deactivate the following day  . eSIM also will deactivate automatically once the data plan  is completely used.
          <div className="docsSubHeading">4. Refund</div>
          In the event if you cancel your room bookings , we will look into the eSIM usage. If you have used more than 50MB of the data we will charge you USD $ 1 / per 100 MB of additional data used from the eSIM .
          <div className="docsSubHeading">5. Device Compatibility</div>
          Please ensure your device is compatible to use the eSIM , device compatibility also depends on the carrier and country of origin , as some  countries have disabled usage of eSIM in certain devices .
          <div className="docsSubHeading">6. Service suspension</div>
          We may suspend or disconnect the service without any prior notice , if we or any lawful government authorities observe any fraudulent use of the eSIM .
          <div className="docsSubHeading">7. Illegal & misuse</div>
          As the owner of the eSIM you are liable for any unauthorized or fraudulent activities conducted by using this eSIM , if required we will furnish all your details to the relevant authorities for their further action .
          <br/><br/>
          If you suspect any breach or unauthorized use of your device or the eSIM , as a device owner please contact customer service immediately so that we can look into the issue and take the necessary action
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default EsimTos;
