import React, {useState, useEffect} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import PaymentElementStripe from "./PaymentElementStripe";
import {_gtag, getIsTestAcc} from "../../Helper";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const isTestAcc = getIsTestAcc();


const stripePromise = isTestAcc ? loadStripe(`${process.env.REACT_APP_STRIPE_KEY_TEST}`) : loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const PaymentCheckout = (props) => {

  _gtag('checkout_progress');

  const clientSecret = new URLSearchParams(window.location.search).get(
      "secret"
  );

  const refId = new URLSearchParams(window.location.search).get(
      "refId"
  );


  const options = {
    appearance : {
      theme: 'stripe',
    },
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
  };


  return (
      <Elements stripe={stripePromise} options={options}>
        <PaymentElementStripe refId={refId} clientSecret={clientSecret}/>
      </Elements>
  );
}

export default PaymentCheckout;
