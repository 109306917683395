import React from "react";
import "antd/dist/antd.css";
import "./LoginModal.css";
import { Form, Input, Button } from "antd";
import Axios from "axios";

// TODO: Google and Facebook Login
// Forgot password backend not available
class ForgotPasswordForm extends React.Component {
  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let response = await Axios.post("users/forget-password", values);

        if (!response.data.success ) {
          this.setState({ errors: response.data.msg });
        } else if (response.data.success) {
          // localStorage.setItem("usertoken", response.data.token);
          // localStorage.setItem("userdata", response.data.userObject.name);
          // localStorage.setItem("useremail", response.data.userObject.email);
          this.props.closeModal();
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        onSubmit={this.handleSubmit}
        className="login-form"
        hideRequiredMark={true}
        colon={false}
      >
        <div className="text" style={{color:'white'}}>
          Please enter your email address so we can send you a link to reset
          your password.
        </div>
        <br />
        <Form.Item label={<span style={{color: 'white'}}>Email</span>}>
          {getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "Please input your email!",
              },
              {
                type: "email",
                message: "Please input a valid email",
              },
            ],
          })(<Input />)}
        </Form.Item>

        <br />

        <Button
          type="primary"
          htmlType="submit"
          onClick={this.handleSubmit}
          style={{ marginRight: "1rem", width: "100%"}}
        >
          Confirm
        </Button>
        {/*<Button type="default" onClick={this.props.closeModal}>*/}
        {/*  Cancel*/}
        {/*</Button>*/}
      </Form>
    );
  }
}

const ForgotPassword = Form.create({ name: "forgot_password" })(
  ForgotPasswordForm
);
export default ForgotPassword;
