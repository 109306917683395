import { Select } from "antd";
import CountryRegionData from "country-region-data/data.json";
import React, { Component } from "react";

const { Option } = Select;

export class RegionSelector extends Component {
  render() {
    const data = CountryRegionData.find(
      (countryInfo) => this.props.country === countryInfo.countryShortCode
    );

    let regionData;
    if (data) {
      regionData = data.regions.map((regionInfo) => (
        <Option key={regionInfo.name} value={regionInfo.name}>
          {regionInfo.name}
        </Option>
      ));
    }

    return (
      <Select
        showSearch
        placeholder={this.props.placeholder || "Select a state"}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={this.props.value}
        onChange={this.props.onChange}
        style={this.props.style}
        disabled={this.props.disabled}
      >
        {!this.props.disabled && regionData}
      </Select>
    );
  }
}
