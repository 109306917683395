import { Skeleton, Spin, Icon } from "antd";
import React,{useState} from "react";
import { RoomSkeleton } from "./RoomSkeleton";
import "./Skeleton.css";
import { useHistory } from "react-router-dom";
import {_cancelCall} from "../../AxiosHelper";

export const DesktopSkeleton = () => {

  const [loadPrevious, setLoadPrevious] = useState(false);

  const history = useHistory();

  const middle = [];
  for (let i = 0; i < 16; i++) {
    middle.push(<RoomSkeleton key={i} />);
  }

  const loader = (
    <Spin
      indicator={
        <Icon
          type="loading-3-quarters"
          style={{ fontSize: "6rem", color: "#EB234A" }}
          spin
        />
      }
    />
  );

  // document.body.style.overflow = "hidden";

  const cancelSearchGoBack = (e) => {
    e.preventDefault();
    _cancelCall();
    history.goBack();
    setLoadPrevious(true)

    // document.body.style.overflow = "inherit";
  }

  return (
    <div className="DesktopSkeleton" style={{ overflow: "auto" }}>
      <div className="modalOverlay">
        <div className="overlayContainer">
          {loader}
          <div style={{ margin: "2rem 0", opacity: 1, zIndex: 20 }}>
            { !loadPrevious ? "Price comparison is going on" : "Loading previous data"}
          </div>
          { !loadPrevious && <a href="#" onClick={cancelSearchGoBack}>Cancel</a>}
        </div>
      </div>
      <div className="title">
        <Skeleton
          title={false}
          paragraph={{ rows: 2, width: ["20vw", "20vw"] }}
          active
        />
      </div>
      <Skeleton title={false} paragraph={{ rows: 1, width: ["23vw"] }} active />

      <div className="DesktopSkeletonInfo">
        <Skeleton
          title={false}
          paragraph={{ rows: 4, width: ["75vw", "75vw", "75vw", "75vw"] }}
          active
        />
      </div>

      <div className="middle">{middle}</div>
    </div>
  );
};
