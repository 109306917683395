import axios from 'axios';
import {getStorage} from "./LocalStorageHelpers";
import {useContext} from "react";
import {getIsTestAcc} from "./Helper";

export const delay = (ms = 1000) => new Promise((resolve) => setTimeout(resolve, ms));

const validURL = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\s\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
  }
  return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
  );
};
export const channelId = () => {
  const isTestAcc = getIsTestAcc();

  return isTestAcc ? process.env.REACT_APP_CHANNEL_ID_TEST : process.env.REACT_APP_CHANNEL_ID;
};

export const providerURL = () => process.env.REACT_APP_PROVIDER_URL;

export const getCurrency = () => {
  const currency = localStorage.getItem("currency");
  if( typeof currency === "string" ) return currency;

  return 'MYR';
}

export const _defaultHeaders = (currency='MYR', correlationId=null) => {

  const customerIp = getStorage('_customer_ip');
  const isTestAcc = getIsTestAcc();

  const _corr = correlationId ?? guid()

  return (
    {
      "Content-Type": "application/json; charset=utf-8",
      // "apiKey" : "aa19bd43-523 f-4c39-bb43-5c996ced7ceb",
      // "Accept-Encoding": " gzip, deflate",
      "customer-ip": customerIp,
      // "customer-ip": '49.37.192.211',
      correlationId: _corr,
      accountId: isTestAcc ? process.env["REACT_APP_ACCOUNT_ID_TEST"] : process.env["REACT_APP_ACCOUNT_ID"], // vd-demo-account
      currency,
    }
  )
}

export const _setupCancelToken = () => {
  const axiosCancelToken = axios.CancelToken.source();

  window.axiosCancel = axiosCancelToken;
}

export const _cancelCall = () => {
  window.axiosCancel.cancel('Manual Cancel');
  _setupCancelToken();
}

export default function setupAxios() {

  _setupCancelToken();

  const setUrl = (config) => {

    if (config.url.includes(['https://cors-anywhere.herokuapp.com/', 'https://nexus.prod-env.vervotech.com']) ){
        return config.url;
    }
    if (config.url.includes('https://nexus.prod-env.vervotech.com')){
        return config.url;
    }

    if (config.url.includes("https://nexus.prod.zentrumhub.com")){
        return config.url;
    }

    if( validURL(config.url) )
        return config.url;

    if( config.url.includes('http') )
        return config.url;

    const explode = config.url.split('/');

    // check prefix slash(/) exist
    const hasSlash = explode[0] === '';

    // set base url for the request
    let baseUrl = config.baseURL;
    if (!baseUrl) {
      baseUrl = process.env.REACT_APP_BACKEND_URL;
      // baseUrl = 'http://localhost:5000'
    }
    baseUrl += '/';

    // get uri and remomve slash if exists
    const uri = hasSlash ? config.url.substr(1) : config.url;

    // final URL : baseURL + country(optional) +  version(optional) + uri
    return `${baseUrl}${uri}`;
  };

  const logResponse = (config, data) => {
    if (config && config.url && config.timeInit) {
      // // eslint-disable-next-line no-console
      // console.log(`ApiRes ([${config.method}]${config.url})`);
      // // eslint-disable-next-line no-console
      // console.log('Server Response', data);
      // // eslint-disable-next-line no-console
      // console.log('Config', config);
      // // eslint-disable-next-line no-console
      // console.log(`Duration : ${Number(new Date().getTime() - config.timeInit) / 1000} seconds`);
    }
  }

  const requestSuccess = async config => {

    // skip interceptor if config in send from axios method
    if (config.skipInterceptor?.request) return config;

    const url = setUrl(config);

    Object.assign(config, {
      url,
      cancelToken: window.axiosCancel.token,
      timeInit : new Date().getTime(),
      // timeout : 10
    });

    // console.log('Request Config', config);

    return config;
  }

  const requestFailed = err => Promise.reject(err);

  const responseSuccess = res => {

    const { config, data } = res

    logResponse(config, data)

    return res;
  }

  // const responseFailed = ({ config, message }) => {
  //   Alert.alert(
  //       'Request Failed',
  //       message,
  //       [
  //         {
  //           text: 'Retry',
  //           onPress: () => axios(config)
  //         },
  //         {
  //           text: 'Cancel',
  //           style: 'cancel',
  //           // onPress: () =>  Promise.reject(message)
  //         },
  //       ],
  //       { cancelable: false }
  //   );
  //
  //   return Promise.reject(message);
  // }


  axios.interceptors.request.use(requestSuccess, requestFailed);
  axios.interceptors.response.use(responseSuccess, requestFailed);
}
