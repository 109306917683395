import React from "react";
import "./LoginModal.css";
import "antd/dist/antd.css";
import { Form, Input, Select, Button } from "antd";
import Axios from "axios";
import { MDBAlert } from "mdbreact";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";

const { Option } = Select;
// TODO: Phone number backend not set up
// Todo: Google Facebook functions not available
class RegistrationForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    error: null,
    registered: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          let response = await Axios.post("users/register", values);
          if (response.data === "Success: Registration successful") {
            this.setState({ registered: true });
          } else {
            this.setState({ error: response.data });
          }
        } catch (error) {
          this.setState({ error: "Error in Signup" });
          console.log(error);
        }
      }
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  responseGoogle = async (response) => {
    console.log(response.profileObj);

    let values = response.profileObj
    values.type = 'google';
    try {
      let response = await Axios.post("users/social", values);
      if (response.data ) {
        this.props.setuser(response.data.userObject.name);
        localStorage.setItem("usertoken", response.data.token);
        localStorage.setItem("userdata", JSON.stringify({
          first_name :response.data.userObject.first_name,
          last_name:response.data.userObject.last_name,
          contactNumber:response.data.userObject.contactNumber,
        }));
        localStorage.setItem("useremail", response.data.userObject.email);
        this.props.closeModal();
      }
    } catch (error) {
      this.setState({ error: "Error in Signup" });
      console.log(error);
    }
  };

  responseFacebook = async (response) => {
    console.log(response);

    let values = {
      email : response.email,
      givenName : response.name,
      familyName : '',
      type : 'facebook',
      socialId : response.userID
    }

    try {
      let response = await Axios.post("users/social", values);
      if (response.data ) {
        this.props.setuser(response.data.userObject.name);
        localStorage.setItem("usertoken", response.data.token);
        localStorage.setItem("userdata", JSON.stringify({
          first_name :response.data.userObject.first_name,
          last_name:response.data.userObject.last_name,
          contactNumber:response.data.userObject.contactNumber,
        }));
        localStorage.setItem("useremail", response.data.userObject.email);
        this.props.closeModal();
      }
    } catch (error) {
      this.setState({ error: "Error in Signup" });
      console.log(error);
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("The passwords you entered do not match.");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    const prefixSelector = getFieldDecorator(
      "prefix",
      {}
    )(<PhoneInput dropdownStyle={{ border: "none" }} />);

    return (
      <Form
        className="login-form"
        colon={false}
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        layout="horizontal"
      >
        {this.state.registered && (
          <MDBAlert color="success">Registration successful</MDBAlert>
        )}
        {this.state.error !== null && (
          <MDBAlert color="danger">{this.state.error}</MDBAlert>
        )}

        <FacebookLogin
          appId="208307229975285"
          fields="name,email,picture"
          callback={this.responseFacebook}
          render={(renderProps) => (
            <Button
              onClick={renderProps.onClick}
              style={{
                backgroundColor: "#405a93",
                color: "white",
                width: "100%",
                padding: "1rem 0",
                height: "auto",
                borderRadius: "3px",
                marginBottom: "1rem",
              }}
            >
              Continue with Facebook
            </Button>
          )}
        />

        <GoogleLogin
          className="loginBtn loginBtn--google"
          clientId="255531455220-0gu1fj71v1jr783csupghm5gcjqq4uc6.apps.googleusercontent.com"
          onSuccess={this.responseGoogle}
          onFailure={this.responseGoogle}
          cookiePolicy={"single_host_origin"}
          buttonText=" Login with Google"
          render={(renderProps) => (
            <Button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              style={{
                backgroundColor: "#cf5b4d",
                color: "white",
                width: "100%",
                padding: "1rem 0",
                height: "auto",
                borderRadius: "1px",
                marginBottom: "1rem",
              }}
            >
              Continue with Google
            </Button>
          )}
        />

        <Form.Item label={<span style={{color: 'white'}}>First Name</span>}>
          {getFieldDecorator("first_name", {
            rules: [
              {
                required: true,
                message: "Please input your first name!",
                whitespace: true,
              },
            ],
          })(<Input />)}
        </Form.Item>

        <Form.Item label={<span style={{color: 'white'}}>Last Name</span>}>
          {getFieldDecorator("last_name", {
            rules: [
              {
                required: true,
                message: "Please input your last name!",
                whitespace: true,
              },
            ],
          })(<Input />)}
        </Form.Item>

        <Form.Item label={<span style={{color: 'white'}}>Email</span>}>
          {getFieldDecorator("email", {
            rules: [
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label={<span style={{color: 'white'}}>Password</span>} hasFeedback>
          {getFieldDecorator("password", {
            rules: [
              {
                required: true,
                message: "Please input your password!",
              },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(<Input.Password />)}
        </Form.Item>
        <Form.Item label={<span style={{color: 'white'}}>Confirm Password</span>} hasFeedback>
          {getFieldDecorator("confirm", {
            rules: [
              {
                required: true,
                message: "Please confirm your password!",
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(<Input.Password onBlur={this.handleConfirmBlur} />)}
        </Form.Item>

        <Form.Item label={<span style={{color: 'white'}}>Phone Number</span>}>
          {getFieldDecorator("contactNumber", {
            rules: [
              { required: true, message: "Please input your phone number!" },
            ],
          })(<PhoneInput placeholder="" inputClass="signUpPhoneInput" />)}
        </Form.Item>

        <Button type="primary" htmlType="submit" style={{width:'100%', marginTop:'20px'}}>
          Register
        </Button>
      </Form>
    );
  }
}

const SignUpModal = Form.create({ name: "register" })(RegistrationForm);

export default SignUpModal;
