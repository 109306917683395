import React, {useEffect, useMemo} from 'react';
import {useLocation} from "react-router-dom";
import Header from "../../components/Header/Header";
import Loader from "../../components/Skeleton/Loader";
import Axios from "axios";
import {getStorage} from "../../LocalStorageHelpers";
import {getIsTestAcc} from "../../Helper";

const BookingOngoing = (data) => {

  const { search } = useLocation();

  const isTestAcc = getIsTestAcc();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const refId = query.get('refId');
  const type = query.get('type');
  const isTest = isTestAcc;
  // const isTest = query.get('isTest');
  // console.log('query', query.get('refId'))
  // console.log('query', data)
  const makeBooking = async () => {
    let config = {
      method: "post",
      url: "/payment/make-booking",
      data: {
        id : refId,
        customerIp : getStorage('_customer_ip'),
        isTest
      },
    };

    // const fakeBookingProcess = () => {
    //   window.location.href  = `/payment/success?refId=${refId}`;
    //   // window.location.href  = `/payment/failed?refId=${refId}`;
    // }
    // setTimeout(() => {
    //   fakeBookingProcess();
    // }, 5000)

    const res = await Axios(config)
        .then((res) => {
          const data = res.data;
          if( data.success ) {
            window.location.href  = `/payment/success?refId=${refId}`;
          }else{
            alert(data.msg);
            window.location.href  = `/payment/failed?refId=${refId}`;
          }
        })
        .finally(() => {
          // window.location.href  = `/payment/success?refId=${refId}`;
        })


  }

  useEffect(() => {
    if( type === 'INPROGRESS' ) makeBooking();
    else{
      alert('Transaction Failed/Canceled');
      window.location.href = '/';
    }
  }, [])

  return (
      <div>
        <Header />
        <Loader text={ type === 'INPROGRESS' ? "Booking in the process..." : 'Transaction Failed/Canceled'}/>
      </div>
  )
}

export default BookingOngoing;
