import React from "react";
import "./SearchBarRoomInfo.css";
import "antd/dist/antd.css";
import { Tabs } from "antd";
import { RoomTab } from "./RoomTab";
const { TabPane } = Tabs;

export default class RoomInfo extends React.Component {
  render() {
    const { panes } = this.props;
    return (
      <Tabs
        type="editable-card"
        onChange={this.props.onChange}
        activeKey={this.props.activeKey}
        onEdit={this.props.onEdit}
        className="RoomInfo"
      >
        {panes.map((pane, i) => (
          <TabPane
            tab={`Room ${i + 1}`}
            key={pane.key}
            closable={pane.closable}
            forceRender={true}
            style={{ paddingBottom: "1rem" }}
          >
            <RoomTab
              pane={pane}
              changeAdultCount={this.props.changeAdultCount}
              changeChildCount={this.props.changeChildCount}
              changeChildAge={this.props.changeChildAge}
            />
          </TabPane>
        ))}
      </Tabs>
    );
  }
}
