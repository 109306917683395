import React from "react";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";
import ForgotPassword from "./ForgotPassword";
import { Modal, Dropdown, Menu } from "antd";
import { Link, Redirect } from "react-router-dom";
import "./Header.css";
import LoginBg from "../../images/login_bg_1.png";

const StatelessHeader = (props) => {
  const {
    user,
    setuser,
    modalVisible,
    setmodalVisible,
    modalType,
    setmodalType,
    openLogin,
    openSignUp,
    providerConfig = {},
    logoColor=null
  } = props;

  const signIn = (
    <div className="buttonContainer">
      <div
        className="button"
        onClick={openSignUp}
        style={{ color: props.signupColor ? props.signupColor : "black" }}
      >
        Sign Up
      </div>
      <div className="button" style={{ color: "#d93b4f" }} onClick={openLogin}>
        Log In
      </div>
    </div>
  );

  const logout = (event) => {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("userdata");
    localStorage.removeItem("useremail");
    localStorage.removeItem("is-test-acc");

    setuser(false);
    return <Redirect to="/" key={"random"} />;
  };

  const menu = (
    <Menu>
      {/*<Menu.Item>*/}
      {/*  <Link to="/transactions">Your Transaction</Link>*/}
      {/*</Menu.Item>*/}
      <Menu.Item>
        <Link to="/Myaccount">My account</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/" onClick={logout}>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  const welcome = (
    <div>
      <Dropdown overlay={menu} placement="bottomLeft" arrow>
        <div style={{ cursor: "pointer" }}>
          Welcome, <b>{user}</b>
        </div>
      </Dropdown>
    </div>
  );

  return (
    <div className="Header" style={props.containerStyle}>
      <Modal
        title={null}
        footer={null}
        visible={modalVisible}
        onCancel={() => setmodalVisible(false)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        bodyStyle={{
          backgroundImage:`url(${LoginBg})`,
          backgroundSize:'cover',
          padding:'30px',
        }}
        className="loginModal"
      >
        <div style={{
          color:'white',
          backgroundColor:'#ffffff30',
          backdropFilter: "blur(5px)",
          padding:10,
        }}>
          { modalType !== "Forgot Password" && <p style={{maxWidth:350, textAlign:'center'}}>{`Dear customer please ${ modalType === "Register" ? "get registered": "login"} to get members only special room rates`} </p> }
          {modalType === "Login" && (
              <LoginModal
                  closeModal={() => setmodalVisible(false)}
                  setmodalType={setmodalType}
                  setuser={setuser}
              />
          )}
          {modalType === "Register" && (
              <SignUpModal
                  closeModal={() => setmodalVisible(false)}
                  setmodalType={setmodalType}
                  setuser={setuser}
              />
          )}
          {modalType === "Forgot Password" && (
              <ForgotPassword closeModal={() => setmodalVisible(false)} />
          )}
        </div>
      </Modal>
      <div className="innercontainer">
        <div style={{ marginRight: "auto" }}>
          <Link to={providerConfig.status ? `/${providerConfig.name}` : '/'}>
            { providerConfig.status ? <div className={`logo-${providerConfig.name} ${logoColor ? logoColor : ''}`} /> : <div className="logo" />}
          </Link>
        </div>
        {user ? welcome : signIn}
      </div>
    </div>
  );
};

export default StatelessHeader;
